/* eslint-disable no-case-declarations, @typescript-eslint/no-unused-vars, no-var, @typescript-eslint/ban-ts-comment */
import { IDeckCard } from "../../components/lists/deck";
import { DeckAnimator } from "../../services/deckAnimator";

import { getCardOffset, debounce } from "../../utils";

import { CardType } from "../types";
/**
 * A library for adding tilt effect to elements.
 */
const UniversalTilt = require("universal-tilt.js");
/**
 * An object containing card frame images.
 * @type {{[key: string]: string}}
 */
const cardFrames: {
  [key: string]: string;
} = {};
/**
 * The DeckCard class represents a card in a deck.
 * @class
 * @param {IDeckCard} card - The card data.
 * @param {HTMLElement} element - The HTML element associated with the card.
 */
class DeckCard {
  data: IDeckCard;
  element: HTMLElement;
  rx = 0;
  cb: EventListenerOrEventListenerObject | undefined;
  /**
   * Creates a new instance of DeckCard.
   * @param {IDeckCard} card - The card data.
   * @param {HTMLElement} element - The HTML element associated with the card.
   */
  constructor(card: IDeckCard, element: HTMLElement) {
    this.data = card;

    this.element = element;
  }
  /**
   * Sets the rotation on the x-axis of the card.
   * @param {number} rx - The new value for rx.
   */
  setRx(rx: number) {
    this.rx = rx;
  }
  /**
   * Positions the card element based on its rotation and coordinates.
   * @param {number} rx - The current value of rx.
   * @param {boolean} mobile - Indicates if the device is a mobile device.
   */
  positionSelf(rx: number, mobile: boolean) {
    const styles = this.element?.style;

    styles && (styles.top = rx - this.data.coord!.yCoord + "px");
    styles &&
      (styles.left =
        rx * (mobile ? 1.015 : 1.006) + this.data.coord!.xCoord + "px");
    styles && (styles.transform = `rotate(${this.data.coord?.angle}deg)`);

    styles && (styles.zIndex = String(this.data.coord?.zCoord));
  }
  /**
   * Returns an event handler function that triggers a pop-out animation for the deck card on mouse enter.
   * @param {DeckAnimator} deckAnimation - The DeckAnimator instance managing the deck animations.
   * @returns {Function} - The event handler function.
   */
  enterHandler(deckAnimation: DeckAnimator) {
    return () => {
      if (deckAnimation.dragged) return;

      const frames = `
        
      @keyframes popOut {
        from {
          
          
          transform: rotate(${
            this.data.coord?.angle
          }deg) scale(0.95) translateY(0);
        }
        to {
          transform: rotate(${
            this.data.coord?.angle
          }deg) scale(1.05) translateY(-${getCardOffset()}%);
          
          z-index: 999;
          
        }
  
      }
      `;

      document.getElementById("dynamicFrames")?.remove();
      const _s = document.createElement("style");
      _s.id = "dynamicFrames";
      _s.innerHTML = frames;
      document.head.appendChild(_s);
      this.element?.classList.add("popOut");

      deckAnimation.stopDeckAnimation();
    };
  }
  /**
   * Returns an event handler function that removes the pop-out animation from the deck card on mouse leave.
   * @param {DeckAnimator} deckAnimation - The DeckAnimator instance managing the deck animations.
   * @returns {Function} - The event handler function.
   */
  leaveHandler(deckAnimation: DeckAnimator) {
    return () => {
      if (deckAnimation.dragged) return;
      this.element?.classList.remove("popOut");

      deckAnimation.startDeckAnimation();
    };
  }
  /**
   * Handles right click cancel event
   * @param deckAnimation - Optional deck animation object
   */
  rightClickCancel(deckAnimation?: DeckAnimator) {
    const deck = typeof deckAnimation !== "undefined";
    document.body.classList.remove("freezeBodyScroll");

    document
      .querySelectorAll("#portal .deckCard-infoCard-Close")
      .forEach((el: Element) => {
        const new_element = el.cloneNode(true);
        el.parentNode?.replaceChild(new_element, el);
      });

    document.getElementById("portal")?.classList.remove("active");

    const card = document.querySelector(
      `#portal ${deck ? ".deckCard" : ".unitCard"}`
    );

    card?.remove();

    deck && this.positionSelf(this.rx, window.innerWidth <= 800);

    this.element.style.display = deck ? "block" : "flex";
    this.element?.classList.remove("menuOpen");
    deckAnimation?.startDeckAnimation(true);
  }

  /**
   * Handles right click event
   * @param yOffset - The offset of the Y-coordinate of the mouse position
   * @param deckAnimation - Optional deck animation object
   * @returns A promise that resolves when the right click menu is closed
   */
  rightClickHandler(
    { yOffset }: { yOffset: number },
    deckAnimation?: DeckAnimator
  ) {
    const mobile = window.innerWidth <= 800;
    return async (e: Event) =>
      new Promise((r) => {
        this.element?.classList.add("menuOpen");
        //this.data.coord?.zCoord
        const infoElement = this.element?.querySelector(
          ".deckCard-infoCard"
        ) as HTMLDivElement;
        if (infoElement) {
          infoElement.style.zIndex = "-1";
          this.element.style.top = "";
          this.element.style.left = "";
          this.element.style.transform = "";
          const cloneCard = this.element.cloneNode(true) as HTMLDivElement;

          this.element.style.display = "none";

          /*cloneCard.style.transform = mobile
            ? ""
            : `translate(-50%, -${yOffset}%) rotate(0deg)`;*/

          cloneCard.classList.remove("popOut");
          document.getElementById("portal")?.appendChild(cloneCard);
          document.getElementById("portal")?.classList.add("active");

          document
            .querySelectorAll("#portal .deckCard-infoCard-Close")
            .forEach((el) => {
              const div = el as HTMLDivElement;
              div.addEventListener("click", (e) => {
                if (e.target !== div) return;
                e.stopPropagation();
                this.rightClickCancel(deckAnimation);
                r(true);
                //mobile && document.getElementById("deckCards")?.scrollIntoView();
              });
            });

          deckAnimation?.stopDeckAnimation(true);
          mobile && document.body.classList.add("freezeBodyScroll");

          !mobile &&
            UniversalTilt.init({
              elements: cloneCard,
              settings: {
                speed: 5,
                max: 8,
                reset: false,
              },
              callbacks: {},
            });
        }

        e.preventDefault();
      });
  }
  /**
   * Load the video for the card.
   * @param {HTMLVideoElement} video - The video element.
   * @returns {Object} - The onload, onerror, and load functions for the video.
   */
  loadVideo(video: HTMLVideoElement) {
    const animSrc = this.data["Animation File"];
    return {
      onload: () => {
        this.element.style.display = "none";
        const parent = this.element.parentElement;
        parent
          ?.querySelectorAll("div.videoContainer")
          .forEach((el) => el.remove());

        const video = document.createElement("video");
        video.autoplay = true;
        video.loop = true;
        video.muted = true;
        video.playsInline = true;
        const container = document.createElement("div");
        container.classList.add("videoContainer");
        const source = document.createElement("source");
        source.src = animSrc;
        source.type = "video/webm";
        video.appendChild(source);

        container.appendChild(video);
        parent?.appendChild(container);
        video.play();
        setTimeout(() => video.classList.add("loadedImage"), 350);
        //video.classList.add("loadedImage");
      },
      onerror: (e: any) => {
        console.log(e.message, "e");
        this.element.style.display = "initial";
      },
      load: (video: HTMLVideoElement) => {
        video.setAttribute("src", animSrc);
        video.load();
      },
    };
  }
  /**
   * Get the card frame.
   * @returns {Promise} - A promise that resolves to the card frame image.
   */
  async getFrame() {
    let path = "";

    const card = this.data;

    switch (card.Type) {
      case CardType.Conjured: {
        path = `${card.Type}/${card.Faction}`;

        break;
      }
      default: {
        path = `${card.Type}/${card.Faction}/${card.Rarity}`;
      }
    }

    if (path.indexOf("undefined") > -1) return;

    if (cardFrames[path]) return cardFrames[path];
    return await new Promise((resolve) =>
      import("../../assets/img/lists/frames/" + path + ".png")
        .then((image: any) => {
          cardFrames[path] = image.default;
          resolve(image.default);
        })
        .catch((error: any) => {
          console.log(error);
          resolve(undefined);
        })
    );
  }
}
/**
 * Create a new DeckCard object.
 * @function
 * @param {IDeckCard} card - The card data.
 * @param {HTMLElement} element - The card element.
 * @returns {DeckCard} - The DeckCard object.
 */
export const deckCardFabric = (card: IDeckCard, element: HTMLElement) =>
  new DeckCard(card, element);

export default DeckCard;
export type { DeckCard };
