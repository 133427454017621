import { RefObject } from "react";
/**
 * Ref type to be used with ImageLoader
 */
type Ref = RefObject<Element>;
/**
 * Callback function type for when a ref intersects or leaves the root element
 */
type Callback = Function | ((val: boolean) => void);
/**
 * Additional options to pass when adding a new ref to the ImageLoader instance
 */
type AddRefOption = {
  observeLeave?: boolean;
};
/**
 * ImageLoaderOptions represents the options that can be passed to the createObserver method of an ImageLoader instance.
 * It determines the root element, root margin and threshold for the intersection observer.
 */
export type ImageLoaderOptions = {
  root: any;
  rootMargin: string;
  threshold: number;
};
/**
 *A utility class for lazy loading images using an IntersectionObserver and queuing image loading requests
 */
class ImageLoader {
  refs: { ref: Ref; cb: Callback; observeLeave: boolean }[] = [];
  fullObserveRefs: Element[] = [];
  loaded: Element[] = [];
  processing = false;

  private queue: { url: string; resolve: any }[] = [];

  observer: IntersectionObserver;
  /**
   *Creates and returns an IntersectionObserver instance
   *@param options Optional object to configure the IntersectionObserver instance
   *@returns A new IntersectionObserver instance
   */
  private Observer(options?: ImageLoaderOptions) {
    return new IntersectionObserver(
      async (entries: IntersectionObserverEntry[]) => {
        for (const entry of entries) {
          const fullOservable = this.fullObserveRefs.indexOf(entry.target) > -1;
          if (entry.isIntersecting || fullOservable) {
            const item = this.refs.find(
              (item) => item.ref.current === entry.target
            );

            const newFullObservale =
              item?.observeLeave &&
              this.fullObserveRefs.indexOf(item.ref.current!) === -1;

            if (newFullObservale) this.fullObserveRefs.push(item.ref.current!);

            if (
              item?.ref.current &&
              this.loaded.indexOf(item.ref.current) === -1
            ) {
              const res = (await item.cb(entry.isIntersecting)) as any;
              if (res) {
                await this.loadBackground(res);
                this.loaded.push(item.ref.current);
              }
            }
          }
        }
      },
      options || {
        root: null,
        rootMargin: `0px 0px ${
          window.innerWidth > 800 ? "1000" : "1500"
        }px 0px`,
        threshold: 0,
      }
    );
  }

  constructor(options?: ImageLoaderOptions) {
    this.observer = this.Observer(options);
  }
  /**
   * Factory method to create a new instance of the ImageLoader class
   * @param options ImageLoaderOptions object to configure IntersectionObserver
   * @returns A new instance of the ImageLoader class
   */
  public createObserver(options: {
    root: HTMLElement;
    rootMargin: string;
    threshold: number;
  }) {
    return new ImageLoader(options);
  }
  /**
   * Adds a new Ref to the refs array to observe for lazy loading and triggers the IntersectionObserver to observe it
   * @param ref RefObject of the element to observe
   * @param setState Function to be called when the element intersects with the viewport
   * @param options Optional object with observeLeave boolean to determine if element should be fully observed after intersecting
   */
  public addRef(ref: Ref, setState: Callback, options?: AddRefOption) {
    if (ref.current && !this.refs.find((item) => item.ref === ref)) {
      this.refs.push({
        ref,
        cb: setState,
        observeLeave: options?.observeLeave || false,
      });
      this.observer.observe(ref.current);
      ref.current.addEventListener(
        "load",
        () => ref.current && this.loaded.push(ref.current)
      );
    }
  }
  /**
   * Loads a background image based on device size
   * @async
   * @param {object} background - Object containing background image information
   * @param {string} background.id - Id of the element to apply the background to
   * @param {object} background.urls - Object containing the URLs for the desktop and mobile versions of the background image
   * @param {string|string[]} background.urls.desk - Desktop version of the background image URL, or an array of URLs
   * @param {string|string[]} [background.urls.mob] - Optional mobile version of the background image URL, or an array of URLs
   * @returns {Promise<boolean>} A promise that resolves to a boolean value indicating if the background image has loaded
   */
  private async loadBackground(background: {
    id: string;
    urls: { desk: string | string[]; mob?: string | string[] };
  }) {
    const node = document.getElementById(background.id);

    const src =
      window.innerWidth > 800
        ? background.urls.desk
        : background.urls.mob || background.urls.desk;
    const srcArr = Array.isArray(src) ? src : [src];

    for (const src of srcArr) {
      await new Promise((resolve, reject) => {
        const image = new Image();
        let loaded = false;

        image.addEventListener("load", function () {
          loaded = true;
          resolve(loaded);
        });
        image.src = src;
        image.onerror = function () {
          reject("could not load an image");
        };
      });
    }

    node?.classList.add("visible");
  }
  /**
   * Queues a URL to be added to the list of backgrounds to be loaded
   * @async
   * @param {string} url - The URL of the background image to add to the queue
   * @returns {Promise<string>} A promise that resolves to the URL of the background image that was added to the queue
   */
  async queueAdd(url: string) {
    const x = await new Promise((resolve) => {
      this.queue.push({
        url,
        resolve,
      });
    }).catch((e) => {
      console.log(e, " d");
      return "#";
    });

    return x;
  }
  /**
   * Starts the process of loading queued background images
   * @returns {this} The instance of the BackgroundLoader class
   */
  load() {
    setInterval(async () => {
      if (this.processing) return;
      this.processing = true;
      while (this.queue.length) {
        await new Promise((resolve) => {
          const item = this.queue.shift();
          const image = new Image();
          image.addEventListener("load", () => {
            item?.resolve(true);
            resolve(true);
          });
          image.src = item?.url!;
          image.onerror = () => {
            item?.resolve(true);
            resolve(true);
          };
        });
      }
      this.processing = false;
    }, 500);

    return this;
  }
}

export default () => new ImageLoader();
export type { ImageLoader };
