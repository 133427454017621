import React from "react";
import BattefieldBoard from "../components/board/battlefield";
import Header from "./common/header";

import "../assets/styles/battlefield.css";

const GameMechanics = () => {
  /**
   * @returns Return Header and Batllefield Board components.
   */
  return (
    <div className="gameMechanicsPage">
      <Header />
      <BattefieldBoard setSliderIndexOnClickBattleField />
    </div>
  );
};

export default GameMechanics;
