export const debounce = (cb: (...args: any) => void, delay: number) => {
  let tm: any;

  return (...args: any) => {
    tm && clearTimeout(tm);
    tm = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};
export const scrollToTheTop = () =>
  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 100);

export const getCardOffset = () => {
  switch (true) {
    case window.innerWidth < 1425: {
      return "15";
    }
    case window.innerWidth < 1215: {
      return "2";
    }
    default:
      return "30";
  }
};
export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
export const sleep = (tm: number) =>
  new Promise((r) => setTimeout(r, tm * 1000));

export function isMacintosh() {
  return navigator.platform.indexOf("Mac") > -1;
}

export function isWindows() {
  return navigator.platform.indexOf("Win") > -1;
}
export const shortScreen = window.innerWidth > 800 && window.innerHeight < 600;
