import { useEffect, useState, useRef } from "react";
import { ICard } from "../../../store/types";

import { CardWrapper } from "./cardWrapper";

import { withServices, ServiceContainer } from "../../../hocs/withServices";

import { useSelector } from "react-redux";
import { ReduxState } from "../../../store";

import { deckCardFabric } from "../../../store/models/card";

//const base = process.env["CARD_ANIMATION_BASE"];
/**
 * Component that renders an image of a card with optional card info shown on click.
 * @param {ICard} unit - The card object to render an image for.
 * @param {string} alt - The alt text for the image.
 * @param {string} className - The class name to apply to the image.
 * @param {ServiceContainer} serviceContainer - The service container for dependency injection.
 * @param {boolean} isVis - A flag indicating whether the card info should be visible.
 * @returns {JSX.Element} The CardImage component.
 */
export const CardImage = withServices(
  ({
    unit,
    isVis,
    alt,
    className,
    serviceContainer,
  }: {
    unit: ICard;
    alt: string;
    className: string;
    serviceContainer: ServiceContainer;
    isVis: boolean;
  }) => {
    const cardInfoShow = useSelector(
      (state: ReduxState) => state.global.cardInfoShow
    ) as number;

    const [url, setUrl] = useState<string>("#");

    const [failed, setFailed] = useState(false);
    /**
     * Callback function to be executed when an image has finished loading.
     * @param {Event} event - The load event to handle.
     * @returns {void}
     */
    const callBackWhenLoaded = function (event: Event) {
      const target = event.target as HTMLDivElement;
      target.classList.add("loadedImage");
      target.parentElement?.classList.add("loadedImage");
    };

    const cardRef = useRef<HTMLImageElement>(null);
    /**
     * React hook that adds a load event listener to the referenced image element and removes it on cleanup.
     * @param {React.MutableRefObject<HTMLImageElement | null>} cardRef - The reference to the image element to add the load event listener to.
     * @returns {void}
     */
    useEffect(() => {
      const currentTarget = cardRef.current as HTMLImageElement | null;

      if (currentTarget) {
        currentTarget.addEventListener("load", callBackWhenLoaded);
        //loadedImage
      }
      return () => {
        if (currentTarget) {
          currentTarget.removeEventListener("load", callBackWhenLoaded);
        }
      };
    }, [cardRef]);
    /**
     * useEffect hook that runs when the cardInfoShow state changes.
     * If the cardInfoShow is truthy and matches the unit ID, and the cardRef's closest ancestor with the .menuOpen class is found,
     * the function loads the card using the deckCardFabric function, creates a new video element, and invokes the loadVideo function of the card object.
     * The loadeddata and error events are then added to the video element, and the load function is called with the video element as an argument.
     * @param {number} cardInfoShow - The ID of the card to display additional information for.
     */
    useEffect(() => {
      if (
        cardInfoShow &&
        cardInfoShow === unit.ID &&
        cardRef.current?.closest(".menuOpen")
      ) {
        const image = document.querySelector(
          "#portal .cardWrapper > img"
        ) as HTMLImageElement;

        const card = deckCardFabric(unit, image);
        const video = document.createElement("video");
        const { onerror, onload, load } = card.loadVideo(video);
        video.addEventListener("loadeddata", onload);
        video.addEventListener("error", onerror);
        load(video);
      }
    }, [cardInfoShow]);
    /**
     * useEffect hook that triggers when isVis or url changes.
     * If isVis is true and url is "#" (i.e. no image is currently loaded), it loads the image from the server and sets the image url when it is loaded.
     * @param {boolean} isVis - A boolean that represents whether the component is currently visible or not.
     * @param {string} url - The current url of the image.
     * @param {ICard} unit - The current card object that contains the image data.
     * @param {ServiceContainer} serviceContainer - An object that contains the necessary services used in the component.
     */
    useEffect(() => {
      if (isVis && url === "#") {
        const src =
          serviceContainer.api.base +
          "/" +
          unit.Faction +
          "/" +
          unit.ID +
          "-" +
          unit.Name_EN +
          ".jpg";
        serviceContainer.imageLoader.queueAdd(src).then(() => setUrl(src));
      }
    }, [isVis]);

    return (
      <CardWrapper
        card={unit}
        //style={{ display: !observedLazy || isVis ? "block" : "none" }}
      >
        <img
          src={url}
          alt={`${alt}-${unit.ID}`}
          referrerPolicy="no-referrer"
          ref={cardRef}
          className={className}
          onError={() => {
            if (url !== "#") {
              setFailed(true);
            }
          }}
        />
        {failed && <div className="failedLoad"></div>}
      </CardWrapper>
    );
  }
);
