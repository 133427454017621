import React, { useState, useEffect, useRef } from "react";
import AliceCarousel from "react-alice-carousel";
/**
 * Represents the optional parameters that can be passed to a component or function.
 * @interface Options
 * @property {number} [paddingLeft] - The left padding of a component.
 * @property {number} [paddingRight] - The right padding of a component.
 * @property {"ltr" | "rtl"} [dir] - The direction of the component.
 * @property {boolean} autoPlay - A boolean value indicating whether a component should automatically start playing.
 * @property {number} [activeIndex] - The index of the active component.
 */
interface Options {
  paddingLeft?: number;
  paddingRight?: number;
  dir?: "ltr" | "rtl";
  autoPlay: boolean;
  activeIndex?: number;
}

export const withFeatureAliceCarousel = (items: any[], options?: Options) => {
  const itemPadding = window.innerWidth < 480 ? 55 : 0;

  const [animate, setAnimate] = useState<boolean>(true);

  const carousel = useRef(null);

  const carousel1 = useRef<AliceCarousel | null>(null);
  /**
   * useEffect hook that observes the intersection of the carousel component and sets animation accordingly.
   * @param carouselRef A reference to the carousel component.
   * @param autoPlay A boolean value indicating whether or not the carousel should automatically play.
   */
  useEffect(() => {
    if (carousel.current) {
      const observer = new IntersectionObserver(
        async (entries: IntersectionObserverEntry[]) => {
          for (const entry of entries) {
            if (entry.isIntersecting == true) {
              setAnimate(entry.isIntersecting);
              entry.isIntersecting && carousel1.current?.slideNext(undefined);
            }
          }
        },
        { root: null, rootMargin: "0px", threshold: 0 }
      );
      observer.observe(carousel.current);
    }
  }, [carousel.current]);

  return (
    <div className="unitCarousel" ref={carousel}>
      <div className="flexRow">
        <AliceCarousel
          infinite={true}
          ref={(node) => {
            if (node) {
              carousel1.current = node;
            }
          }}
          mouseTracking={true}
          disableButtonsControls
          autoPlay={animate}
          animationDuration={animate ? 10 * 300 : undefined}
          autoPlayInterval={animate ? 0 : undefined}
          animationEasingFunction="linear"
          //autoPlayDirection={dir}
          disableDotsControls={true}
          autoPlayStrategy="none"
          paddingLeft={options?.paddingLeft || itemPadding}
          paddingRight={options?.paddingRight || itemPadding}
          items={items}
          responsive={{
            0: {
              items: 1,
            },
            480: {
              items: 2,
            },
            800: {
              items: 3,
            },
            1124: {
              items: 4,
            },
            1650: {
              items: 5,
            },
            2000: {
              items: 6,
            },
          }}
        />
      </div>
    </div>
  );
};
