/**
 * Interface representing the properties of a card rarity.
 * @interface CardRarity
 * @property {string} rarity - The rarity of the card.
 * @property {number} points - The number of points associated with the card rarity.
 */
export interface CardRarity {
  rarity: string;
  points: number;
}
/**
 * Represents the rarities of a cards.
 * 
 * @typedef {CardRarity[]}
 * @property {string} rarity - The rarity of the cards.
 * @typedef {number} points - The number of points associated with rarity.
 */
export const cardRarityData: CardRarity[] = [
  {
    rarity: "Basic",
    points: 1,
  },
  {
    rarity: "Simple",
    points: 2,
  },
  {
    rarity: "Common",
    points: 3,
  },
  {
    rarity: "Uncommon",
    points: 4,
  },
  {
    rarity: "Rare",
    points: 5,
  },
  {
    rarity: "Epic",
    points: 6,
  },
  {
    rarity: "Legendary",
    points: 7,
  },
  {
    rarity: "Mythic",
    points: 8,
  },
];
