/**
 * Enums for Card Types, Factions, Rows, Rarity are defined here.
 */
export enum CardType {
  Unit = "Unit",
  Hero = "Hero",
  Spell = "Spell",
  Conjured = "Conjured",
}

export enum Factions {
  Infernal = "Infernal",
  Ephemeral = "Ephemeral",
  Celestial = "Celestial",
}

export enum Rows {
  Artillery = "Artillery",
  Brawler = "Brawler",
  Ranged = "Ranged",
}

export enum Rarity {
  Basic = "Basic",
  Simple = "Simple",
  Common = "Common",
  Uncommon = "Uncommon",
  Rare = "Rare",
  Epic = "Epic",
  Legendary = "Legendary",
  Mythic = "Mythic",
}
/**
 * ICard interface defined matching columns in the database.
 */
export interface ICard {
  _id: string;
  Name_EN: string;
  Description_EN: string;
  Img: string;
  Type: CardType;
  Faction: Factions;
  ID: number;
  Rarity: Rarity;
  "Base Power": number;
  Row: Rows;
  Quote_EN: string;
  Decklimit: number;
  Introduced: boolean;
  Ability: string;
  "Ability Unlock lvl": number;
  Animated: boolean;
  Cut: boolean;
  "Voice File": string;
  "Effect File": string;
  "L1 Image file": string;
  "Animation File": string;
  "Direct Img Link": string;
  abilityData?: IAbility;
}
/**
 * Interface for IAbility that matches relevant columns in the database.
 */
export interface IAbility {
  _id: string;
  Name: string;
  "Target Card Type": string;
  Description: string;
}
/**
 * Interface for IMechanic that matches relevant columns in the database.
 */
export interface IMechanic {
  Location: string;
  Outline: string;
  Text: string;
}

export type MapLocation = {
  outline?: string;
  label?: string;
  bgImage?: string;
  bgIcon?: string;
  bgIconHover?: string;
};
/**
 * Interface for IBattleground that matches relevant columns in the database.
 */
export type IBattleground = {
  ID: number;
  Display_Name: string;
  "Battleground Image": string;
  Description_EN: string;
  "Battlefield Image": string;
  Overlay_Link: string;
  "Map Area": string;
  Background_Sound_Link: string;
  Game_Won_Sound_Link: string;
  Round_Won_Sound_Link: string;
  Round_Lost_Sound_Link: string;
  Game_Lost_Sound_Link: string;
  Effect: string;
  Resources: string;
};
/**
 * Interface for IUser that matches relevant columns in the database.
 */
export type IUser = {
  Email: string;
  Wallet: string;
  Marketing_Emails: boolean;
  BrowserName: string;
  OS_Name: string;
  Device_Type: string;
  Referral_Code: string;
};