import React from "react";
/**
 * The consumer component of the ServiceContext. It provides a way to access the value prop provided by the nearest ancestor ServiceProvider component.
 * The provider component of the ServiceContext. It provides a value prop that can be accessed by child components wrapped in a ServiceConsumer component.
 * @name ServiceConsumer
 * @type {React.ComponentType<React.ConsumerProps<object>>}
 * @name ServiceProvider
 * @type {React.ComponentType<{value: object}>}
 */
const { Provider: ServiceProvider, Consumer: ServiceConsumer } =
  React.createContext({});

export { ServiceProvider, ServiceConsumer };
