import { useEffect, useState } from "react";
/**
 * Represents the size of the browser window
 * @interface Size
 * @property {number|undefined} width - The width of the browser window
 * @property {number|undefined} height - The height of the browser window
 */
interface Size {
  width: number | undefined;
  height: number | undefined;
}
/**
 * A custom React hook that tracks the size of the browser window and updates the state whenever the window is resized.
 * @function useWindowSize
 * @returns {Size} The current size of the browser window as a Size object
 */
export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener for window resize and call handleResize function
    window.addEventListener("resize", handleResize);
    // Call handleResize function initially to set initial state
    handleResize();
    // Remove event listener when component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
