import { createSlice } from "@reduxjs/toolkit";
import { Action } from "..";
import { IMechanic } from "../types";
/**
 * Interface for the Mechanics slice of the Redux state
 */
export interface Mechanics {
  items: IMechanic[];
}
/**
 * The initial state for the Mechanics slice of the Redux state
 */
export const initialState: Mechanics = {
  items: [],
};
/**
 * Action interface for loading mechanics
 */
interface ALoadCards extends Action {
  payload: {
    mechanics: IMechanic[];
  };
}
/**
 * Slice for handling mechanics-related data.
 */
export const mechanics = createSlice({
  name: "cards",
  initialState,
  reducers: {
    /**
     * Updates the state with the given mechanics data.
     * @param state - Current state of the slice
     * @param action - Action that triggered the state update
     */
    loadMechanics: (state: Mechanics, action: ALoadCards) => {
      state.items = action.payload.mechanics;
    },
  },
});

export const { loadMechanics } = mechanics.actions;
