import React, { useMemo } from "react";

import { ITestimonial } from "../testimonial";

import pad from "../../../assets/img/home/mob/ff.png";

import { TestimonialTemplate } from "../testimonial";
import { withFeatureAliceCarousel } from "../../../hocs/withFeatureCarousel";
/**
 * Renders a testimonal card for mobile devices
 * @param {Object} props - The component props
 * @param {IFeatureCard} props.testimonal - The testimonal card to display
 * @returns {JSX.Element} - The rendered component
 */
const Testimonial = ({ testimonial }: { testimonial: ITestimonial }) => {
  return (
    <div className="mobileFeature mobTestimonial">
      <img src={pad} alt="wPad" className="woodenPad" />
      <TestimonialTemplate card={testimonial} />
    </div>
  );
};
/**
 * A carousel component for displaying testimonal cards.
 * @param {Object} props - The props object.
 * @param {ITestimonial[]} props.testimonals - An array of feature cards.
 * @returns {JSX.Element} - The TestimonalCarousel component.
 */
export const TestimonialCarousel = ({ testimonials }: { testimonials: ITestimonial[] }) => {
  const testimonialCards = useMemo(
    () =>
    testimonials.map((testimonial, index) => (
        <Testimonial testimonial={testimonial} key={`FeatureMob-${index}`} />
      )),
    [testimonials]
  );

  return withFeatureAliceCarousel(testimonialCards, {
    paddingLeft: 40,
    paddingRight: 40,
    autoPlay: true,
  });
};
