import React, { useRef, useEffect, useState } from "react";

import { ITextBlock, TextBlock } from "../elements/textBlock";

import { world } from "../../mockData";

import { withServices, ServiceContainer } from "../../hocs/withServices";

import { createPortal } from "react-dom";

import { ReactComponent as Close } from "../../assets/img/icons/close.svg";

import { mapUrl } from "../../mockData";
/**
 * Interface for defining a world section which extends the ITextBlock interface and adds picUrl property.
 */
export interface IWorld extends ITextBlock {
  picUrl: string;
}
/**
 * Default source URL for the iframe element.
 */
const defaultSrc = "#";
/**
 * A component representing the world section of the website.
 * It loads images using the imageLoader service from the ServiceContainer object.
 * @param props - Component props which include the serviceContainer object.
 * @returns A React component representing the world section.
 */
export const World = withServices((props) => {
  const card: IWorld = world;

  const { serviceContainer }: { serviceContainer: ServiceContainer } = props;

  const [intersected, setIntersected] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const portal = document.getElementById("portal");
  const background = useRef(null);
  const image = useRef(null);
  const iframe = useRef(null);
  const [src, setSrc] = useState(defaultSrc);
  const mapContainer = useRef<HTMLDivElement>(null);
  /**
   * Adds image references to the image loader and sets the intersected state for lazy loading images.
   * @param {React.RefObject} image - Reference to the image element.
   * @param {Function} setIntersected - Setter function for the intersected state.
   * @param {ServiceContainer} serviceContainer - The container for the image loader.
   */
  useEffect(() => {
    serviceContainer.imageLoader.addRef(image, setIntersected);
    serviceContainer.imageLoader.addRef(background, (load) => {
      if (load) {
        return {
          id: "worldSection",
          urls: {
            desk: require("../../assets/img/home/worldGhostBg.png").default,
            mob: require("../../assets/img/home/mob/mobCastle2.png").default,
          },
        };
      }
    });
  }, [serviceContainer.imageLoader]);
  /**
   * Controls the visibility of the map container and sets the source of the iframe element.
   * @param {boolean} showMap - Boolean flag indicating whether to show the map or not.
   * @param {string} mapUrl - The URL of the map.
   * @param {React.RefObject} mapContainer - Reference to the map container element.
   * @param {string} src - The source URL of the iframe element.
   */
  useEffect(() => {
    const container = mapContainer.current;
    if (container) {
      //first time
      if (showMap) {
        if (src === defaultSrc) {
          setSrc(mapUrl || "");
        }
        container?.classList.add("visible");
      } else {
        container?.classList.remove("visible");
      }
    }
  }, [showMap, mapContainer.current]);

  return (
    <div className="flexRow worldCard oddItem" ref={background}>
      {portal &&
        createPortal(
          <div ref={mapContainer} className="portalContainer" id="mapContainer">
            <Close className="close" onClick={() => setShowMap(false)} />
            <div id="myWindow" className="embed-responsive-item">
              <iframe
                src={src}
                width="100%"
                height="100%"
                ref={iframe}
              ></iframe>
            </div>
          </div>,
          portal
        )}
      <div className="wordCard-picture-wrapper">
        <img
          src={intersected ? card.picUrl : "#"}
          alt="newWorld"
          className="wordCard-picture"
          ref={image}
        />
      </div>
      <div className="wordCard-intro" id="mapLink">
        <TextBlock card={card} />

        <span className="learMore" onClick={() => setShowMap(true)}>
          OPEN MAP
        </span>
      </div>
    </div>
  );
});
