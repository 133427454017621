import React from "react";

import { ServiceConsumer } from "./ServcieProvder";

import { Api } from "../services/api";
import { ImageLoader } from "../services/imageLoader";
import { DeckAnimator } from "../services/deckAnimator";

/**
 * Interface for Service Providers
 * @typedef {Object} ServiceProvider
 * @property {string} api - provide api endpoint for service
 * @property {string} imageLoader - provide imageLoader endpoint for service
 * @property {string} deckAnimator - provide deckAnimator
 */
export interface ServiceContainer {
  api: Api;
  imageLoader: ImageLoader;
  deck: DeckAnimator;
}
/**
 * A higher-order component that injects the serviceContainer and setContainer props into the wrapped component.
 * @function withServices
 * @param {React.FC<any>} Wrapped - The component to be wrapped
 * @returns {React.FC<any>} - A new component that injects the serviceContainer and setContainer props
 */
const withServices = (Wrapped: React.FC<any>) => {
  return (props: any) => (
    <ServiceConsumer>
      {({ serviceContainer, setContainer }: any) => (
        <Wrapped
          {...props}
          serviceContainer={serviceContainer}
          setContainer={setContainer}
        />
      )}
    </ServiceConsumer>
  );
};

export { withServices };
