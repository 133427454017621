import { configureStore, createSlice } from "@reduxjs/toolkit";
import { cards, Cards } from "./reducer/cards";
import { mechanics, Mechanics } from "./reducer/mechanics";
import { battlegrounds, Battlegrounds } from "./reducer/battleground";
import { users, Users } from "./reducer/users";
/**
 * Defines the shape of the Redux state.
 */
export interface ReduxState {
  cards: Cards;
  mechanics: Mechanics;
  battlegrounds: Battlegrounds;
  global: any;
  users: Users;
}

export interface Action {
  type: string;
}
/**
 * Defines the shape of the "setIntersected" action payload.
 */
interface AIntersected extends Action {
  payload: {
    sector: string;
    value: boolean;
  };
}
/**
 * Defines the shape of the "toggleCardInfo" action payload.
 */
interface AtoggleInfoShow extends Action {
  payload: number;
}
/**
 * Defines the shape of the "toggleCardIndex" action payload.
 */
interface AtoggleInfoShowIndex extends Action {
  payload: number;
}
/**
 * The shape of the state slice.
 * @typedef {Object} GlobalState
 * @property {boolean} deck - Whether the deck section is intersecting the viewport.
 * @property {number} cardInfoShow - The ID of the card whose information is being shown.
 * @property {number} index - The index of the current card in the deck section.
 */

/**
 * The initial state of the global state slice.
 * @typedef {GlobalState}
 */
export const global = createSlice({
  name: "global",
  initialState: {
    deck: false,
    cardInfoShow: 0,
    index: 2,
  },
  reducers: {
    /**
     * Toggles the display of card information.
     * @function
     * @param {GlobalState} state - The current state slice.
     * @param {PayloadAction<number>} action - The action containing the card ID.
     */
    toggleCardInfo: (state: any, action: AtoggleInfoShow) => {
      state.cardInfoShow = action.payload;
    },
    /**
     * Sets the index of the current card in the deck section.
     * @function
     * @param {GlobalState} state - The current state slice.
     * @param {PayloadAction<number>} action - The action containing the card index.
     */
    toggleCardIndex: (state: any, action: AtoggleInfoShowIndex) => {
      state.index = action.payload;
    },
    /**
     * Sets the intersection status of a section.
     * @function
     * @param {GlobalState} state - The current state slice.
     * @param {PayloadAction<{sector: string, value: boolean}>} action - The action containing the sector and value.
     */
    setIntersected: (state: any, action: AIntersected) => {
      state[action.payload.sector] = action.payload.value;
    },
  },
});
export const { setIntersected, toggleCardInfo, toggleCardIndex } =
  global.actions;
export default configureStore<ReduxState>({
  reducer: {
    cards: cards.reducer,
    mechanics: mechanics.reducer,
    battlegrounds: battlegrounds.reducer,
    global: global.reducer,
    users: users.reducer,
  },
});
