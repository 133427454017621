import * as React from "react";
/**
 * interface for ITextBlock
 * @interface
 * @property {string} title - The title of the textblock.
 * @property {string} text - The text of the textblock.
 * @property {string} link - The link in the textblock.
 * @property {string} buttonText - The text of the button in textblock.
 */
export interface ITextBlock {
  title: string;
  text: string;
  link?: string;
  buttonText?: string;
}
/**
 * Functional component that renders a card text block.
 * @param {Object} props - Component props.
 * @param {ITextBlock} props.card - Object representing the card text block, including a title, text, and link.
 * @returns {JSX.Element} - Rendered component.
 */
export const TextBlock = ({ card }: { card: ITextBlock }) => (
  <div className="cardText">
    <h3>{card.title}</h3>
    <p>
      {card.text}
      {card?.text?.trim()?.at(-1) === "." ? "" : "."}
    </p>
    {card.link && (
      <a href={card.link} className="learMore" target="_blank">
        {card.buttonText ? card.buttonText : "Open Wiki"}
      </a>
    )}
  </div>
);
