import { useRef, useEffect, useState } from "react";

import { withServices, ServiceContainer } from "../../hocs/withServices";

import { ITextBlock, TextBlock } from "../../components/elements/textBlock";

import { wiki } from "../../mockData";

import { sleep, iOS } from "../../utils";
import wikiAnim from "../../assets/animations/wikia.webm";

export interface IWiki extends ITextBlock {}
/**
 * A functional component that renders a Wiki section and takes in an arbitrary props object.
 * @param {Object} props - The props object that is passed down to the component.
 * @returns {JSX.Element} - The JSX element that represents the rendered Wiki section.
 */
const WikiSection = (props: any) => {
  const [animUrl, setUrl] = useState("");

  const { serviceContainer }: { serviceContainer: ServiceContainer } = props;
  const noVideo =
    iOS() || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  /**
   * A hook that uses the service container's image loader to load the background image and video of the Wiki section.
   * @param {Object} props - The props object that is passed down to the component.
   * @param {ServiceContainer} props.serviceContainer - The service container object that provides the image loader.
   * @param {HTMLDivElement} props.background - The reference to the div element that contains the background.
   * @param {string} props.wikiAnim - The URL string of the Wiki section's animation video.
   * @param {boolean} props.noVideo - A boolean value that indicates whether the video should be loaded or not.
   * @returns {undefined}
   */
  useEffect(() => {
    serviceContainer.imageLoader.addRef(background, async (load) => {
      if (load) {
        const src = wikiAnim;
        if (!noVideo) {
          const video = document.createElement("video");
          video.addEventListener("canplay", () => {
            setUrl(src);
          });
          video.addEventListener("error", (e) => {
            console.log(e, "error");
          });
          video.src = src;
          video.load();

          await sleep(3);
        }

        return {
          id: "wikiSection",
          urls: {
            desk: require("../../assets/img/home/wikia.png").default,
            mob: null && [
              require("../../assets/img/home/mob/mobThrone2.png").default,
              require("../../assets/img/home/mob/mobThrone3.png").default,
            ],
          },
        };
      }
    });
  }, [serviceContainer.imageLoader]);

  const background = useRef(null);

  return (
    <section
      className="homeSection wikiSection"
      id="wikiSection"
      ref={background}
    >
      <div className="sectionContainer wikiContainer">
        <h3>Find out more</h3>
        <h2>
          <span>dfiance wiki</span>
        </h2>

        <div className="container ">
          <div className={`bookBg ${noVideo ? "" : "ios"}`}>
            {animUrl && (
              <video playsInline autoPlay muted loop className="wikiAnim">
                <source src={animUrl} />
              </video>
            )}

            <div className="cardText" id="royal">
              <TextBlock card={wiki} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withServices(WikiSection);
