import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import { ServiceProvider } from "./hocs/ServcieProvder";

import { Provider } from "react-redux";

import api from "./services/api";
import ImageLoader from "./services/imageLoader";
import DeckAnimator from "./services/deckAnimator";

import store from "./store";

import { ServiceContainer } from "./hocs/withServices";
import { AppContainer } from "./components/containers/App";
import { Router } from "./pages/router";
import "./assets/styles/reset.css";
import "./assets/styles/global.css";
import "./assets/styles/fontface.css";
import "./assets/styles/animations.css";
import "./assets/styles/main.css";
import "./assets/styles/cards.css";
//import "./assets/styles/map.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "./assets/styles/media.css";
import { apiURL } from "./config/keys";
/**
 * Represents the main App component of the application
 * @function App
 * @returns {JSX.Element} Returns a JSX element containing the entire application
 */
const App = () => {
  //localStorage.clear();
  /**
   * Represents the container for all services of the application
   * @typedef {Array}
   * @property {function} api - The function for making API requests
   * @property {function} imageLoader - The function for loading images
   * @property {function} deck - The function for animating decks
   */
  const [serviceContainer, setContainer] = useState<ServiceContainer>({
    api: api(apiURL || ""),
    imageLoader: ImageLoader(),
    deck: DeckAnimator(),
  });
  /**
   * Represents whether the application has finished loading
   * @typedef {boolean}
   */
  const [loaded, setLoaded] = useState(false);
  /**
   * A useEffect hook that triggers once when the component is mounted to the DOM
   * @function useEffect
   * @param {function} () - The callback function to be executed
   * @returns {undefined}
   */
  useEffect(() => {
    setLoaded(true);
    serviceContainer.imageLoader.load();
  }, [serviceContainer.imageLoader]);
  /**
   * Represents the router of the application
   * @typedef {object}
   */
  const router = useMemo(() => AppContainer(Router), []);

  return (
    /**
     * Provides the entire application with the service container
     * @returns {JSX.Element} Returns a JSX element containing the application
     */
    <ServiceProvider value={{ serviceContainer, setContainer }}>
      <Provider store={store}>{loaded && router({})}</Provider>
    </ServiceProvider>
  );
};
/**
 * Declares the global variables of the application
 * @typedef {object} Window
 * @property {function} api - The function for making API requests
 * @property {object} ethereum - The object representing the Ethereum blockchain
 * @property {function} reload - The function for reloading the application
 */
declare global {
  interface Window {
    api?: any;
    ethereum?: any;
    reload?: any;
  }
}
/**
 * Renders the App component to the DOM
 * @function ReactDOM.render
 * @param {JSX.Element} App - The App component to be rendered
 * @param {HTMLElement} document.getElementById("root") - The element on the DOM to be rendered on
 * @returns {undefined}
 */
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
