import React from "react";

import { ReactComponent as Medium } from "../../assets/img/icons/MIcon.svg";
import { ReactComponent as Twitter } from "../../assets/img/icons/TwitterIcon.svg";
import { ReactComponent as Telegram } from "../../assets/img/icons/telegram.svg";
import { ReactComponent as Discord } from "../../assets/img/icons/discordIcon.svg";
/**
 * Renders a list of social media icons that link to their respective pages.
 */
export const Socials = () => (
  <ul className="socials">
    <li>
      <a href={process.env.REACT_APP_BLOG_LINK} target="_blank">
        <Medium />
      </a>
    </li>
    <li>
      <a href={process.env.REACT_APP_TWITTER_LINK} target="_blank">
        <Twitter />
      </a>
    </li>
    <li>
      <a href={process.env.REACT_APP_TELEGRAM_LINK} target="_blank">
        <Telegram />
      </a>
    </li>
    <li>
      <a href={process.env.REACT_APP_DISCORD_LINK} target="_blank">
        <div className="discord-icon">
          <Discord />
        </div>
      </a>
    </li>
  </ul>
);
