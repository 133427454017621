import React, { useEffect } from "react";

import { ITextBlock } from "../../components/elements/textBlock";

import { FeatureCarousel } from "../../components/elements/carousels/featureCarousel";
import { FeatureSliderCards } from "../../components/lists/sliderCards";
import { FeatureTemplate } from "../../components/elements/feature";

import { features } from "../../mockData";

import { ISection } from "../types";

import { withLazyLoad } from "../../hocs/withlazyLoad";
/**
 * Interface for the IWiki object, which extends the ITextBlock interface.
 * @typedef {Object} IWiki
 * @extends {ITextBlock}
 */
export interface IWiki extends ITextBlock {}
/**
 * Interface for the IFeatureSection object, which extends the ISection interface.
 * @typedef {Object} IFeatureSection
 * @extends {ISection}
 */
interface IFeatureSection extends ISection {}
/**
 * A functional component that renders a feature section and takes in an IFeatureSection props object.
 * @param {IFeatureSection} props - The props object that contains mobile and backgroundRef properties.
 * @param {boolean} props.mobile - A boolean that indicates whether the section is being viewed on mobile or not.
 * @param {Object} props.backgroundRef - A reference object for the section's background.
 * @returns {JSX.Element} - The JSX element that represents the rendered feature section.
 */
const FeatureSection = (props: IFeatureSection) => {
  const { mobile, backgroundRef } = props;
  /**
   * A React hook that preloads images for the component.
   */
  useEffect(() => {
    new Image().src = "../../assets/img/home/prevActive.svg";
    new Image().src = "../../assets/img/home/nextActive.svg";
    new Image().src = "../../assets/img/home/Illustration Hor.png";
  }, []);
  /**
   * A function that handles a click event and adds an active animation class to the background reference's shine container.
   */
  const clickHandler = () => {
    const shine = backgroundRef.current?.querySelector(
      ".shineContainer"
    ) as HTMLDivElement;

    shine.classList.add("activeAnimation");
  };

  return (
    <section
      className="homeSection featureSection ghostBg"
      ref={backgroundRef}
      onClick={clickHandler}
    >
      <div className="sectionContainer featuresContainer">
        <h2>
          <span>FEATURES</span>
        </h2>
        {mobile && <FeatureCarousel features={features} />}
        <FeatureSliderCards data={features}>
          <FeatureTemplate />
        </FeatureSliderCards>
      </div>
      <div className="ruggedLine"></div>
    </section>
  );
};
/**
 * A Higher Order Component that takes in a FeatureSection component and a callback function to be executed on intersection.
 * @param {React.FC} FeatureSection - A React functional component that renders a feature section.
 * @param {Function} callback - A function that is executed when the component intersects with the viewport.
 * @param {Object} options - An object that contains options for the intersection observer.
 * @param {Object} options.root - The element that is used as the viewport for checking visibility of the target. Defaults to the browser viewport if not specified or if null.
 * @param {string} options.rootMargin - A string which specifies a set of offsets to add to the root's bounding box when calculating intersections. Can have values similar to the CSS margin property. Defaults to "0px 0px 0px 0px".
 * @param {number} options.threshold - A number or array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed. Defaults to 0.
 * @returns {Function} - The wrapped component.
 */
export default withLazyLoad(FeatureSection as React.FC)(
  (intersected) => {
    const furtherArrow = document.querySelector(
      "body main .further"
    ) as HTMLImageElement;

    const scrollUpArrow = document.querySelector(
      "footer .scrollUp"
    ) as HTMLImageElement;

    if (intersected || window.scrollY > 1000) {
      furtherArrow.style.display = "none";
      scrollUpArrow.classList.add("vis");
    } else {
      furtherArrow.style.display = "block";
      scrollUpArrow.classList.remove("vis");
    }
  },
  {
    root: null,
    rootMargin: "0px 0px -50px 0px",
    threshold: 0,
  }
);
