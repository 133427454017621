import React, { useRef, useEffect } from "react";

import { withServices } from "./withServices";
import { withServicesProps } from "../pages/types";

import { ImageLoaderOptions, ImageLoader } from "../services/imageLoader";

import { ISection } from "../pages/types";
/**
 * Props for the withLazyLoad HOC.
 * @typedef {Object} withLazyLoadProps
 * @property {ServiceContainer} serviceContainer - The service container containing dependencies needed by the HOC
 * @property {boolean} mobile - A boolean indicating whether or not the component is being rendered on a mobile device
 */
type withLazyLoadProps = withServicesProps & { mobile: boolean };
/**
 * A higher-order component that provides lazy loading functionality to a component.
 * @function withLazyLoad
 * @param {React.FC<ISection>} Wrapped - The component to be wrapped
 * @returns {Function} - A higher-order function that takes a callback function and optional observer options and returns a new component with lazy loading functionality.
 */
export const withLazyLoad =
  (Wrapped: React.FC<ISection>) =>
  (
    /**
     * The callback function that is passed to the withLazyLoad HOC.
     * @callback intersectionCallback
     * @param {boolean} intersected - A boolean indicating whether or not the wrapped component has intersected the viewport
     */
    intersectionCallback: (intersected: boolean) => void,
    observerOptions?: ImageLoaderOptions
  ) =>
    /**
     * The callback function component returned by the withLazyLoad HOC.
     * @function Callback
     * @param {withLazyLoadProps} props - Props injected by the withServices HOC and the withLazyLoad HOC
     * @returns {React.FC<ISection>} - A new component with lazy loading functionality injected via props
     */
    withServices(function Callback(props: withLazyLoadProps) {
      const { serviceContainer, mobile } = props;
      const backgroundRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        let intersector: ImageLoader = serviceContainer?.imageLoader;
        if (observerOptions && !mobile) {
          intersector = intersector.createObserver(observerOptions);
        }

        intersector.addRef(backgroundRef, intersectionCallback, {
          observeLeave: true,
        });
      }, [serviceContainer?.imageLoader]);

      return <Wrapped backgroundRef={backgroundRef} mobile={props.mobile} />;
    });
