import React, {
  useRef,
  useEffect,
  useState /*, useState, useCallback*/,
} from "react";

import { IFaction } from "../../lists/factions";

import { ICard } from "../../../store/types";

import { useSelector } from "react-redux";

import { ReduxState } from "../../../store";

import { CardImage } from "../card/card";

import { withAliceCarousel } from "../../../hocs/withCarousel";

import { deckCardFabric } from "../../../store/models/card";

import { CardInfo } from "../card/cardInfo";

import { useDispatch } from "react-redux";

import { shortScreen } from "../../../utils";

import { toggleCardInfo, toggleCardIndex } from "../../../store/index";

import { useWindowSize } from "../../../hooks/useSize";
/**
 * Component for rendering a single card.
 * @param {Object} props - The props object
 * @param {ICard} props.unit - The card object to be rendered
 * @param {number} props.index - The index of the card in the deck
 * @returns {JSX.Element} - Returns a React JSX element
 */
const Card = ({ unit, index }: { unit: ICard; index: number }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const mobile = Number(size.width) < 800;
  const [visible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  /**
   * Callback function for the IntersectionObserver
   * @param {IntersectionObserverEntry[]} entries - The entries being observed
   */
  const callBackWhenObserver = (entries: any) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const element = cardRef?.current as HTMLDivElement;

    if (element) {
      const card = deckCardFabric(unit, element);
      /**
       * Event listener function for right-click (desktop) or click (mobile) on the card
       * @param {Event} e - The click event
       */
      const rightClick = async (e: Event) => {
        const closePopuo = card.rightClickHandler({
          yOffset: shortScreen ? 35 : 50,
        });

        dispatch(toggleCardInfo(unit.ID));
        dispatch(toggleCardIndex(index));
        await closePopuo(e);
        const event = new MouseEvent("mouseup", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        element.dispatchEvent(event);
        dispatch(toggleCardInfo(0));
        // dispatch(toggleCardIndex(index));
      };
      // Add event listeners based on mobile or desktop
      !mobile && element.addEventListener("contextmenu", rightClick);

      mobile && element.addEventListener("click", rightClick);

      // Set up IntersectionObserver to track when card is in view
      const observer = new IntersectionObserver(callBackWhenObserver, {
        root: document.querySelector(".factionContainer .factionCards"),
        rootMargin: "100px 100px 100px 100px",
        threshold: 0,
      });

      const currentTarget = cardRef.current as HTMLImageElement | null;

      currentTarget && observer.observe(currentTarget);
      return () => {
        element.addEventListener("click", rightClick);
        element?.removeEventListener("contextmenu", rightClick);
      };
    }
  }, [unit, cardRef, mobile]);

  return (
    <div
      className={`unitCard flexCol`}
      key={`unitCard-${unit.ID}`}
      ref={cardRef}
    >
      <CardImage unit={unit} alt={"unitCarousel"} isVis={visible} />
      <CardInfo card={unit} />

      <p className="upperText">{unit.Name_EN}</p>
    </div>
  );
};
/**
 * Component for displaying a carousel of units from a faction
 * @param {Object} props - Component props
 * @param {IFaction} props.faction - The faction object containing the units to display
 * @returns {JSX.Element} - The rendered component
 */
export const UnitCarousel = ({ faction }: { faction: IFaction }) => {
  const cardInfoShow = useSelector(
    (state: ReduxState) => state.global.cardInfoShow
  ) as number;

  const cardInfoShowIndex = useSelector(
    (state: ReduxState) => state.global.index
  ) as number;
  return (
    <>
      {withAliceCarousel(
        faction.units
          .filter((unit) => unit.Img)
          .map((unit, index) => (
            <Card unit={unit} key={`Card-${unit.ID}`} index={index} />
          )),
        {
          autoPlay: cardInfoShow === 0 ? true : false,
          activeIndex: cardInfoShowIndex,
        }
      )}
    </>
  );
};
