/* eslint-disable no-case-declarations, @typescript-eslint/no-unused-vars, no-var, @typescript-eslint/ban-ts-comment */
import { IDeckCard } from "../components/lists/deck";
import { debounce } from "../utils";

let controller = new AbortController();
/**
 * Gets the current rotation of an HTMLDivElement in degrees, taking into account browser-specific CSS transform properties
 * @param {HTMLDivElement} el - The element to get the rotation of
 * @returns {number} The current rotation of the element in degrees
 */
function getCurrentRotationFixed(el: HTMLDivElement) {
  const st = window.getComputedStyle(el, null);
  const tr =
    st.getPropertyValue("-webkit-transform") ||
    st.getPropertyValue("-moz-transform") ||
    st.getPropertyValue("-ms-transform") ||
    st.getPropertyValue("-o-transform") ||
    st.getPropertyValue("transform") ||
    "fail...";

  let angle: number;

  if (tr !== "none") {
    let values: string | string[] = tr?.split("(")[1];
    values = values.split(")")[0];
    values = values.split(",");
    const a = +values[0];
    const b = +values[1];

    let radians = Math.atan2(b, a);
    if (radians < 0) {
      radians += 2 * Math.PI;
    }
    angle = Math.round(radians * (180 / Math.PI));
  } else {
    angle = 0;
  }

  // works!
  return angle;
}

const VELOCITY = 40;
/**
 * Creates a move handler function for rotating an HTML element based on mouse/touch movement
 * @param {number} initialAngle - The starting angle of the element in degrees
 * @param {HTMLDivElement} animation - The element to rotate
 * @param {{x: number; y: number;}} clickCoord - The coordinates of the mouse click/touch start event
 * @param {boolean} touch - A boolean indicating if the event is a touch event (true) or a mouse event (false)
 * @param {(angle: number) => void} preserveAngle - A function to call to preserve the current angle of the element
 * @returns {Function} The move handler function for rotating the element based on mouse/touch movement
 */
const moveHandler = (
  initialAngle: number,
  animation: HTMLDivElement,
  clickCoord: {
    x: number;
    y: number;
  },
  touch: boolean,
  preserveAngle: (angle: number) => void
) => {
  return touch
    ? debounce(function (e: TouchEvent) {
        //cacl rotation angle based click coordinates and mouse movement offset
        const angle =
          e?.changedTouches[0] &&
          initialAngle +
            Math.atan2(
              e?.changedTouches[0]?.clientX - clickCoord.x,
              e?.changedTouches[0]?.clientY
            ) *
              (VELOCITY - 20);
        animation.style.transform = `translateX(-50%) rotate(${angle}deg)`;
        preserveAngle(angle);
      }, 3)
    : debounce(function (e: MouseEvent) {
        //cacl rotation angle based click coordinates and mouse movement offset
        const angle =
          initialAngle + Math.atan2(e.pageX - clickCoord.x, e.pageY) * VELOCITY;
        animation.style.transform = `translateX(-50%) rotate(${angle}deg) `;
        preserveAngle(angle);
      }, 3);
};
/**
 * Class representing a deck animator.
 */
class DeckAnimator {
  private a = 404;
  element: HTMLElement | undefined;
  card: IDeckCard | undefined;
  private cardClickStop = false;
  cb: EventListenerOrEventListenerObject | undefined;
  dragged = false;
  newAngle = 0;
  dir: "left" | "right" = "right";
  /**
   * Calculate theta for each card based on the number of cards.
   * @param data - Array of deck cards.
   * @returns An array of theta values.
   */
  public caclTheta(data: IDeckCard[]) {
    const theta: number[] = [];

    const frags = 360 / data.length;

    for (let i = 0; i <= data.length; i++) {
      theta.push((frags / 180) * i * Math.PI);
    }

    return theta;
  }
  /**
   * Calculate size of the deck based on radius.
   * @param rx - Radius of the deck.
   * @returns Object containing height and width.
   */
  public calcSize = (rx: number) => ({
    height: rx * 2 + this.a,
    width: rx * 2 + this.a,
  });
  /**
   * Calculate the X coordinate for a card based on radius and theta.
   * @param rx - Radius of the deck.
   * @param theta - Theta value of the card.
   * @returns The X coordinate of the card.
   */
  public calcX = (rx: number, theta: number) =>
    Math.round(rx * Math.cos(theta));
  /**
   * Calculate the Y coordinate for a card based on radius and theta.
   * @param rx - Radius of the deck.
   * @param theta - Theta value of the card.
   * @returns The Y coordinate of the card.
   */
  public calcY = (rx: number, theta: number) =>
    Math.round(rx * Math.sin(theta));
  /**
   * Calculate the z-index for a card based on its index and the length of the deck.
   * @param index - The index of the card.
   * @param length - The length of the deck.
   * @returns The z-index of the card.
   */
  public calcZ = (index: number, length: number) => length - Math.abs(index);
  /**
   * Calculate the angle for a card based on its index and the length of the deck.
   * @param index - The index of the card.
   * @param length - The length of the deck.
   * @returns The angle of the card.
   */
  public calcAngle = (index: number, length: number) =>
    -index * (360 / length) - 270;
  /**
   * Stop the deck animation.
   * @param cardClickStop - Whether the deck animation was stopped due to a card click.
   */
  public stopDeckAnimation(cardClickStop?: boolean) {
    const animation = document.getElementById("deckAnimation");

    if (cardClickStop) {
      this.cardClickStop = cardClickStop;
    }

    if (!animation?.classList.contains("paused"))
      animation?.classList.add("paused");
  }
  /**
   * Starts the deck animation.
   * @param {boolean} [cardClickStart] - Indicates if the animation is started from a card click event.
   */
  public startDeckAnimation(cardClickStart?: boolean) {
    const animation = document.getElementById("deckAnimation");

    if (this.cardClickStop && !cardClickStart) return;

    this.cardClickStop = false;

    //false &&
    animation?.classList.contains("paused") &&
      animation?.classList.remove("paused");
  }
  /**
   * Appends keyframe to style element for spin animation.
   * @param {number} deg - The degree value for the rotation.
   */
  appendKeyFrame(deg: number) {
    const keyframe = `
    @keyframes spin {
      from {
        transform: translateX(-50%) rotate(${String(deg)}deg);
        left: 50%;
      }
      to {
        transform: translateX(-50%) rotate(${String(
          this.dir === "right" ? deg + 360 : deg - 360
        )}deg);
        left: 50%;
      }
    }`;

    document.getElementById("dynamicSpin")?.remove();
    const _s = document.createElement("style");
    _s.id = "dynamicSpin";
    _s.innerHTML = keyframe;
    document.head.appendChild(_s);
  }
  /**
   * Returns a function to handle the "mouseup" event for the left mouse button.
   * @param {HTMLDivElement} animation - The HTML element representing the animation.
   * @returns {Function} - The function to handle the "mouseup" event for the left mouse button.
   */
  leftMouseUp(animation: HTMLDivElement) {
    return () => {
      this.dragged = false;
      animation.parentElement?.classList.remove("dragOn");

      animation?.classList.contains("paused") &&
        animation?.classList.remove("paused");

      //insert new default keyFrame in dom
      this.appendKeyFrame(+this.newAngle.toFixed(2));
      //inherit animation from css
      animation.style.removeProperty("transform");
      animation.style.animation = "";
      this.cb && animation.removeEventListener("mousemove", this.cb);
      this.cb && animation.removeEventListener("touchmove", this.cb);
      controller.abort();
      controller = new AbortController();
    };
  }
  /**
   * Returns a function to handle the "mousedown" event for the left mouse button.
   * @param {HTMLDivElement} animation - The HTML element representing the animation.
   * @returns {Function} - The function to handle the "mousedown" event for the left mouse button.
   */
  leftMouseDown(animation: HTMLDivElement) {
    return (e: MouseEvent | TouchEvent) => {
      const { signal } = controller;
      const container = animation.parentElement!;
      //cancel all poped cards
      container
        ?.querySelectorAll(".popOut")
        .forEach((el) => el.classList.remove("popOut"));
      this.dragged = true;
      container.classList.add("dragOn");
      //get initial Angle from animation matrix
      // const matrix = getComputedStyle(animation).getPropertyValue("transform");
      //const x = new WebKitCSSMatrix(getComputedStyle(animation).transform);
      //console.log(x);
      const initialAngle = getCurrentRotationFixed(animation);
      //create handler
      if ((e as MouseEvent).pageX) {
        this.cb = moveHandler(
          initialAngle,
          animation,
          {
            x: (e as MouseEvent).pageX,
            y: (e as MouseEvent).pageY,
          },
          false,
          (newAngle: number) => {
            //save angle and choose direction
            this.dir = newAngle > initialAngle ? "right" : "left";
            this.newAngle = newAngle;
          }
        );
        animation.addEventListener("mousemove", this.cb);
      } else {
        this.cb = moveHandler(
          initialAngle,
          animation,
          {
            x: (e as TouchEvent)?.changedTouches[0]?.clientX,
            y: (e as TouchEvent)?.changedTouches[0]?.clientY,
          },
          true,
          (newAngle: number) => {
            //save angle and choose direction
            this.dir = newAngle > initialAngle ? "right" : "left";
            this.newAngle = newAngle;
          }
        );

        animation.addEventListener("touchmove", this.cb, { signal });
      }

      //stop default animation
      animation.style.animation = "none";
      //preserve angle of default animation
      animation.style.transform = `translateX(-50%) rotate(${initialAngle}deg)`;
      //listen mouse movement
    };
  }
}

export default () => new DeckAnimator();
export type { DeckAnimator };
