import React from "react";
/**
 * @function (Terms) - Renders the Terms component.
 * @returns Terms function on application
 */
const Terms = () => {
  return (
    <div className="terms-page">
      <h1 style={{ fontSize: "35px" }}>Dfiance - Beta Terms & Conditions</h1>
      <br />
      <p>Last Updated: June 19th, 2023</p>
      <br />
      <p>
        Dfiance is player vs player (PVP) strategy card game that simulates a 
		meeting of two opposite armies on a battlefield. The game features 
		stories, characters, maps, events, sound tracks, sound effects as well 
		as battlegrounds, game logic, abilities and many more features.
		The game was created by Games Matter Ltd in cooperation with Swiss-based
		Wings Stiftung. The game is planed to utilize collectible assets in
        order to enable players to own, transfer, and trade digital cards and
        assets, which can then be visualized via different mediums such as
        different websites (the “Websites”) or, web applications or mobile
        applications that the player can interact with (the “Applications”).
        Wings Stiftung and Games Matter Ltd (collectively "Dfiance", "we", or
        "us") are making the Applications available to you free of charge.
        Before you use any of the Applications or acquire, by any means, any
        type of digital assets, however, you will need to review these Terms of
        Use and any terms and conditions incorporated herein by reference
        (collectively, these “Terms").
      </p>
      <br />
      <p>
        Please read these terms carefully before using the applications or any
        of the Dfiance websites. These terms govern your use of the Applications,
        The websites, and all digital assets you intend to use, acquire, or have
        acquired, by any means. You are only allowed to use Dfiance
        Applications, Websites, and/or Digital Assets if you fully understand
        and agree with these Terms and conditions. By using the Applications
        and/or the Websites, or any part of each, you are confirming you
        understand and agree to be bound by all of these Terms and conditions.
        If you are accepting these Terms and conditions on behalf of a company,
        individual, or other legal entity, you represent that you have the legal
        authority to accept these Terms on that entity’s behalf, in which case
        “you” will mean that entity. If you do not have such authority, or if
        you do not accept all of these Terms and conditions, then you may not
        make any use whatsoever of the Applications, Websites, and Digital
        Assets. If you do not agree to these Terms and conditions, you may not
        access or use the Applications and/or the Websites, and/or any Digital
        Assets created by us.
      </p>
      <br />
      <p>
        These Terms are subject to change at the sole consideration of Dfiance,
        according to section 10 of these Terms. If you will decide that you do
        not agree with the changes, you will need to terminate your engagement
        with Dfiance and immediately stop making use of the Applications,
        Websites, and Digital Assets.
      </p>
      <br />
      <p>
        The Company uses your data to provide and improve the Service(s). By
        using an Application or a Website or a Digital Asset provided by the Company, 
		you agree to the collection and to the use of the collected information in 
		accordance with this document.{" "}
      </p>
      <br />
      <p>DEFINITIONS</p>
      <br />
      <p>
		“Card(s)” - illustration(s) in form of digital trading Card(s) that are used
		within the Dfiance games and Applications. In the future, specifically in the
		production version of the Dfiance Game, Cards may become digital collectibles 
		and have a preset maximum number of copies in existence, depending on
		their “Rarity”. Although this is replicated in the Beta version of the
		Dfiance Game, it is not enforced during the Beta stage through distributed 
		technologies.
        <br />
        <br />
        <p>
		“Rarity” - defines the maximum number of copies in existence for each
		Card, to be enforced through Distributed Technologies (only in the future
		production version of the Dfiance Game). Rarity is shown within the
		Dfiance Game using a special frame around the illustration. As of the
		writing of this document, 8 different types of rarities exist (Basic,
		Simple, Common, Uncommon, Rare, Epic, Legendary, and Mythic), however,
		the number, names, and display method of the rarity are subject to
		change at any time at the sole consideration of Dfiance.
        </p>
        <br />
        <p>
		“Distributed Technology” - are systems made of multiple computers that 
		record transactions, run programs and store data on a public ledger.
		Such data includes verifiable transactions as well as information about 
		Digital Assets and other Collectibles. 
        </p>
        <br />
        <p>
		“Wings Foundation” - Wings Stiftung, a Swiss-based not-for-profit
		Foundation that runs, directly or through service providers, the
		Dfiance Game and Website.
        </p>
        <br />
        <p>
		“Games Matter Ltd” - the Corporation that, in cooperation with the
		Wings Foundation, owns all the initial rights to all Dfiance digital
		and non-digital assets, IP, copyrights, and technology.
        </p>
        <br />
        <p>“Dfiance” - Wings Foundation and Games Matter Ltd together.</p>
        <br />
        <p>
		“Dfiance Game \ Game” - One of the Applications developed by Dfiance.
		Specifically, a player Vs player turn-based strategy cards game that 
		integrates a range of different Digital Assets and Collectibles.
        </p>
        <br />
        <p>
		“Digital Assets” - Digital Illustrations (such as Cards and/or Battlegrounds),
		Non-Fungible Digital Tokens, Semi-Fungible Digital Tokens, Fungible
		Digital Tokens, Digital collectibles, and any Digital items such as
		non-collectibles, Avatars, Regalia (e.g armors), Music Tracks, and anything
		else created by Dfiance as well as any mix of the above. In the Beta
		version of the Game, all Digital Assets will be stored in a centralized 
		manner and their Rarity will not be enforced through distributed technologies.
        </p>
        <br />
        <p>
		“Account” - A central database entry or array of entries that serves
		as your digital identity when using the Dfiance Game. The Account may
		be associated with your social profile, e-mail address, phone number,
		or digital/electronic wallet address used to create and/or connect the
		account. 3rd party providers (such as but not limited to, Google or
		Discord) may also be used to create an Account. All your Digital
		Assets earned or acquired in Dfiance Games will be credited to your
		Account. Accounts created and accessed using digital wallets (such
		as Metamask) or 3rd party applications such as Google or Discord may
		not be restored by us. Sharing Accounts or creating multiple Accounts
		are not allowed and both are considered a violation of these Terms.
        </p>
        <br />
        <p>
		“Sign Up / Register” - The process of creating an Account with us. By
		registering and creating an Account you understand, agree with, and
		approve these Terms and Conditions, as well as the Privacy Policy
		available at{" "}
          <a
            href="https://dfiance.com/privacy-policy"
            style={{ color: "black", textDecoration: "underline" }}
          >
            https://dfiance.com/privacy-policy
          </a>
        </p>
        <br />
        <p>
          “Login / Sign In” - The process of verification of your digital
          identity and ownership of the Account.
        </p>
        <br />
        <p>
		“Free Deck” - A minimum number of Soulbound Illustration-type Digital
		Assets of different Rarities associated with your Account, granted at
		no cost, which will enable you to play the Game without having to make
		any purchase. During the Beta stage of the Game, the Free Deck serves
		as a temporary means to access the Beta and it, along with all its
		associated illustrations, may and likely will be completely removed
		from your Account at the end of the Beta.
        </p>
        <br />
        <p>
		“Soulbound” - defines the ability to transfer a Digital Asset between
		Accounts. A Soulbound Digital Asset cannot be transferred to another
		Account but can be used within the Dfiance Game by the associated
		Account. During the Beta stage of the Game, all Digital Assets are
		Soulbound and may only be unlocked for testing purposes.
        </p>
        <br />
        <p>
		“Beta Tester Rewards” - refers to 1 digital illustration (Card) per
		Account that is credited to the Account of players that play at least
		10 matches during the Game Beta phase. This reward, granted by Dfiance
		at its sole consideration, will survive the Beta phase and make it to
		the production phase of the game. It is hereby clarified that any and
		all features of the Card, including the illustration itself and any
		in-Game stats (ATP, ability, faction, rarity, deployment row, and
		level) are subject to changes during the Beta phase of the game as
		part of the Game balancing process. Any and all other Beta Tester
		Rewards granted by Dfiance to players during the Beta phase are
		subject to the same potential changes and updates.
        </p>
        <br />
        <p>
		“Social Channels” - refers to Dfiance’s official social media channels
		such as Twitter account, Discord, and Telegram.
        </p>
        <br />
        <p>
		“Beta / Open Beta” - The period of time during which the Dfiance Game
		will be undergoing comprehensive testing, calibration, and balancing.
        </p>
        <br />
        <p>1. Dfiance Beta</p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.1&nbsp;&nbsp; The Dfiance Game is planned to be open to the public
		during the Beta period/stage. Before you continue to create an
		Account, it is important to understand that the Beta stage is
		associated with significant software issues (bugs), partial or
		complete loss of data, extended downtime, severe lack of stability,
		unannounced and/or complete changes to existing assets, and more
		unforeseen issues.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.2&nbsp;&nbsp; At the end of the Beta period, which shall be
		announced in advance through our Social channels, all player progress
		will be reset and lost, including any and all acquired Digital Assets,
		experience, upgrades, et cetera. The Beta Tester Rewards shall survive
		the Beta stage.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.3&nbsp;&nbsp; There are no purchases of any kind in the Game Beta
		version. Dfiance will not try to sell you any Digital Assets
		(including any Cards or Resources or any other Asset of any kind).
		Digital Assets can only be acquired for free simply by playing the
		Game or by spending in-Game earned Resources.
        </p>
        <br />
        <p>2. Dfiance Card Game</p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.1&nbsp;&nbsp; Upon Registering and performing a Login, each player
		will be created an Account and granted a Free Deck in order to be able
		to play the Game. All elements of the Free Deck such as the
		randomization formula, the Rarity of the Cards, and the number of
		Cards shall be subject to the sole consideration of Dfiance.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.2&nbsp;&nbsp; Upon the release of the production version of the Game
		(after the Beta stage is successfully completed), Cards and other
		Digital Assets are planned (subject to change) to be deployed on a
		Distributed Techology solution of choice, while the creation of 
		Digital Assets may occur on a separate Distributed Technology solution. 
		This mechanism will allow the verification of the total number of copies 
		of each Card and all other Digital Assets. Players will be required to 
		re-acquire all Digital Assets during the production version of the Game, 
		with the exception of Beta Tester Rewards.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.3&nbsp;&nbsp; The statistical probabilities of receiving a
		particular Card Rarity during the Free Deck faction selection depend
		on a mathematical formula that remains the sole consideration of
		Dfiance. One of the goals of the Beta is to properly calibrate the
		free deck probabilities.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.4&nbsp;&nbsp; The Dfiance Card Game Beta is only available on a
		Desktop device and must be used through a supported web browser.
		Mobile is currently not supported.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.5&nbsp;&nbsp; You must provide accurate, up-to-date, and complete
		registration information when you create an Account with us. You are
		required to maintain and promptly update as necessary your Account
		information in case of changes.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.6&nbsp;&nbsp; You are solely responsible for the security of your
		Account and your Digital Assets wallet, if applicable. If you become
		aware of any unauthorized access to your Account, you must quickly
		notify us at{" "}
          <a
            href="accounts@dfiance.com"
            style={{ color: "black", textDecoration: "underline" }}
          >
            accounts@dfiance.com
          </a>
        </p>
        <br />
        <p>2. Ownership and Restrictions</p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.7&nbsp;&nbsp; You understand, acknowledge, and agree that, unless
		otherwise stated in writing, Dfiance owns all legal rights, titles,
		and interests in and to any and all elements of the Applications, and
		all intellectual property rights therein. This includes but is not
		limited to the interfaces, graphics (including any drawings or art),
		designs, systems, methods, information, computer code, software,
		services, the general “look and feel” of the Applications,
		organization, all code, data (textual or otherwise), and all other
		elements of the Applications (collectively, the “Dfiance Materials”)
		are owned by Dfiance and are protected by copyright, trade dress,
		patent, and trademark laws, international conventions, other relevant
		intellectual property and proprietary rights, and applicable laws. All
		Dfiance Materials are the copyrighted property of Dfiance or its
		licensors, and all trademarks, service marks, and trade names
		contained in the Dfiance Materials are proprietary to Dfiance or its
		licensors. Except as expressly set forth herein, your use of the
		Applications does not grant you ownership of or any other rights with
		respect to any content, code, data, or other materials that you may
		access on or through the Applications.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.8&nbsp;&nbsp; We reserve all rights in and to the Dfiance Materials
		not expressly granted to you in the Terms. For the sake of clarity,
		you fully understand and agree: (i) that your acquisition of a Card or
		other Digital Asset, whether via the Applications or otherwise,
		whether paid for in “real-world money” or in-Game Resources or
		received for at no cost, does not give you any rights or licenses in
		or to any Dfiance Materials (including, without limitation, our
		copyright in and to the art and drawings associated with that Card or
		Digital Asset or the associated Cards unlocked through it) other than
		those expressly contained in these Terms; and (ii) that you do not
		have the right to reproduce, distribute, or otherwise commercialize
		any elements of the Dfiance Materials (including, without limitation,
		our copyright in and to the art and drawings associated with Cards or
		Digital Assets) in any way without our prior written consent in each
		case, which consent we may withhold in our sole and absolute
		discretion.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.9&nbsp;&nbsp; You may choose to submit comments, bug reports, ideas,
		or other feedback about the Applications, including without limitation
		about how to improve the Applications (collectively, “Feedback”).
		While we encourage this type of Feedback, you understand and agree
		that by submitting any Feedback, you agree that we are free to use
		such Feedback at our discretion and without additional compensation to
		you, and to disclose such Feedback to third parties (whether on a
		non-confidential basis or otherwise). You hereby grant us a perpetual,
		irrevocable, nonexclusive, worldwide license under all rights
		necessary for us to incorporate and use your Feedback for any purpose.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.10&nbsp;&nbsp; You agree that you are responsible for your own
		conduct while accessing or using the Applications, and for any
		consequences thereof. You agree to use the Applications only for
		purposes that are legal, proper, and in accordance with these Terms
		and any applicable laws or regulations. By way of example, and not as
		a limitation, you may not, and may not allow any third party to: (i)
		send, upload, distribute or disseminate any unlawful, defamatory,
		harassing, abusive, fraudulent, obscene, or otherwise objectionable
		content; (ii) distribute viruses, worms, defects, Trojan horses,
		corrupted files, hoaxes, or any other items of a destructive or
		deceptive nature; (iii) impersonate another person (via the use of an
		email address or otherwise); (iv) upload, post, transmit or otherwise
		make available through the Applications any content that infringes the
		intellectual proprietary rights of any party; (v) use the Applications
		to violate the legal rights (such as rights of privacy and publicity)
		of others; (vi) engage in, promote, or encourage illegal activity
		(including, without limitation, money laundering); (vii) interfere
		with other users’ enjoyment of the Applications; (viii) exploit the
		Applications for any unauthorized commercial purpose; (ix) modify,
		adapt, translate, or reverse engineer any portion of the Applications;
		(x) remove any copyright, trademark or other proprietary rights
		notices contained in or on the Applications or any part of it; (xi)
		reformat or frame any portion of the Applications; (xii) display any
		content on the Applications that contains any hate-related or violent
		content or contains any other material, products or services that
		violate or encourage conduct that would violate any criminal laws, any
		other applicable laws, or any third party rights; (xiii) use any
		robot, spider, site search/retrieval application, or other device to
		retrieve or index any portion of the Applications or the content
		posted on the Applications, or to collect information about its users
		for any unauthorized purpose; (xiv) create user accounts by automated
		means or under false or fraudulent pretenses; or (xv) access or use
		the Applications for the purpose of creating a product or service that
		is competitive with any of our products or services.
        </p>
        <br />
        <p>4. Termination</p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		You may terminate these Terms at any time by canceling your Account on
		the Applications and discontinuing your access to and use of the
		Applications. You agree that we, in our sole discretion and for any or
		no reason, may terminate these Terms and suspend and/or terminate your
		Account(s) for the Applications. You agree that any suspension or
		termination of your access to the Applications may be without prior
		notice, and that we will not be liable to you or to any third party
		for any such suspension or termination. If we terminate these Terms or
		suspend or terminate your access to or use of the Applications due to
		your breach of these Terms or any suspected fraudulent, abusive, or
		illegal activity, then termination of these Terms will be in addition
		to any other remedies we may have at law or in equity. Upon any
		termination or expiration of these Terms, whether by you or us, you
		may no longer have access to information that you have posted on the
		Applications or that is related to your Account, and you acknowledge
		that we will have no obligation to maintain any such information in
		our databases or to forward any such information to you or to any
		third party. To terminate your Account with us you must email us from
		your account email address at{" "}
		<a
		href="accounts@dfiance.com"
		style={{ color: "black", textDecoration: "underline" }}
		>
		accounts@dfiance.com
		</a>{" "}
		with the subject - Account Termination Request - “Your Account Email
		Address”. If you did not register using an email address and instead
		used an electronic / digital wallet such as metamask, you may email us 
		from any address using the subject - Account Termination Request - “Your
		Public electronic / digital wallet Address” + you must add to the body of
		the email a digital wallet-signed (by the respective electronic / digital 
		wallet) message which says: “I request my Dfiance Account to be
		removed and permanently deleted”. You may use
		<a
		href="https://www.myetherwallet.com/interface/sign-message"
		style={{ color: "black", textDecoration: "underline" }}
		>
		https://www.myetherwallet.com/interface/sign-message
		</a>{" "}
		or a similar tool to sign such a message.
        </p>
        <br />
        <p>5. Disclaimers</p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.11&nbsp;&nbsp; You expressly understand and agree that your access
          to and use of the applications is at your sole risk, and that the app
          is provided "as is" and "as available" without warranties of any kind,
          whether express or implied. To the fullest extent permissible pursuant
          to applicable law, we, our subsidiaries, affiliates, and licensors
          make no express warranties and hereby disclaim all implied warranties
          regarding the app and any part of it (including, without limitation,
          the site, any smart contract, or any external websites), including the
          implied warranties of merchantability, fitness for a particular
          purpose, non-infringement, correctness, accuracy, or reliability.
          Without limiting the generality of the foregoing, we, our
          subsidiaries, affiliates, and licensors do not represent or warrant to
          you that: (i) your access to or use of the app will meet your
          requirements, (ii) your access to or use of the app will be
          uninterrupted, timely, secure or free from error, (iii) usage data
          provided through the app will be accurate, (iii) the app or any
          content, services, or features made available on or through the app
          are free of viruses or other harmful components, or (iv) that any data
          that you disclose when you use the app will be secure. Some
          jurisdictions do not allow the exclusion of implied warranties in
          contracts with consumers, so some or all of the above exclusions may
          not apply to you.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.12&nbsp;&nbsp; You accept the inherent security risks of providing
          information and dealing online over the internet, and agree that we
          have no liability or responsibility for any breach of security unless
          it is due to our intentional gross negligence.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.13&nbsp;&nbsp; We will not be responsible or liable to you for any
		losses you incur as the result of your use of an electronic or
		digital wallet such as metamask, including but not limited to
		any losses, damages, and/or claims arising from: (a) user error, such
		as forgotten passwords, lost keys or incorrectly construed (smart) contracts 
		and/or other transactions; (b) server failure and/or data and/or
		Digital Assets and/or digital currency loss; (c) corrupted  files; (d) 
		unauthorized access or activities by third parties, including but not limited 
		to the use of viruses, phishing, bruteforcing or other means of attack
		against the Applications, and/or electronic/digital wallet; (e) damages 
		and/or losses that result from unauthorized  administrative access to Dfiance 
		Applications and/or Websites; (f) negligence of Dfiance employees and/or 
		service providers. 
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.14&nbsp;&nbsp; Dfiance is not responsible for losses due to Distributed 
		Technologies errors, faults, bugs, hacks, loss of access (e.g private keys) 
		or any other features of your electronic wallet or Account.
        </p>
        <br />
        <p>6. Limitation of Liability</p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.15&nbsp;&nbsp; You understand and agree that we, our subsidiaries,
          affiliates, and licensors will not be liable to you or to any third
          party for any indirect, incidental, special, consequential, or
          exemplary damages which you may incur, howsoever caused and under any
          theory of liability, including, without limitation, any loss of
          profits (whether incurred directly or indirectly), loss of goodwill or
          business reputation, loss of data, cost of procurement of substitute
          goods or services, or any other intangible loss, even if we have been
          advised of the possibility of such damages.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.16&nbsp;&nbsp; You agree that our total, aggregate liability to you
          for any and all claims arising out of or relating to these terms or
          your access to or use of (or your inability to access or use) any
          portion of the applications, whether in contract, tort, strict
          liability, or any other legal theory, is limited to the greater of (a)
          the amounts you actually paid dfiance under these terms in the 12
          month period preceding the date the claim arose, or (b) $10.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.17&nbsp;&nbsp; You acknowledge and agree that we have made the
          applications available to you and entered into these terms in reliance
          upon the warranty disclaimers and limitations of liability set forth
          herein, which reflect a reasonable and fair allocation of risk between
          the parties and form an essential basis of the bargain between us. We
          would not be able to provide the app to you without these limitations.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.18&nbsp;&nbsp; You acknowledge and agree that you do not reside in a
          region that explicitly bans the digital assets (as defined in these
          terms or otherwise).
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.19&nbsp;&nbsp;Some jurisdictions do not allow the exclusion or
          limitation of incidental or consequential damages, and some
          jurisdictions also limit disclaimers or limitations of liability for
          personal injury from consumer products, so the above limitations may
          not apply to personal injury claims.
        </p>
        <br />
        <p>7. Assumption of Risk</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          You accept and acknowledge each of the following:
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.20&nbsp;&nbsp;A lack of use or public interest in the creation and
          development of the Dfiance Applications could negatively impact the
          development of the Dfiance Applications and could lead to the
          discontinuation of the project.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
		1.21&nbsp;&nbsp;The regulatory regime governing Distributed Technologies 
		is still uncertain, and new regulations or policies may materially adversely 
		affect the development of the Dfiance Applications.
        </p>
        <br />
        <p>8. Indemnification</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          You agree to hold harmless and indemnify Dfiance and its subsidiaries,
          affiliates, officers, agents, employees, advertisers, licensors,
          suppliers or partners from and against any claim, liability, loss,
          damage (actual and consequential) of any kind or nature, suit,
          judgment, litigation cost, and attorneys’ fees arising out of or in
          any way related to (i) your breach of these Terms, (ii) your misuse of
          the Applications, (iii) your violation of applicable laws, rules or
          regulations in connection with your access to or use of the
          Applications, or (iv) your breach of our Privacy Policy. You agree
          that Dfiance will have control of the defense or settlement of any
          such claims.
        </p>
        <br />
        <p>9. External Sites</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          The Applications may include hyperlinks to other websites or resources
          (collectively, “External Sites”), which are provided solely as a
          convenience. We have no control over any External Sites. You
          acknowledge and agree that we are not responsible for the availability
          of any External Sites and that we do not endorse any advertising,
          products, or other materials on or made available from any External
          Sites. Furthermore, you acknowledge and agree that we are not liable
          for any loss or damage which may be incurred as a result of the
          availability or unavailability of the External Sites, or as a result
          of any reliance placed by you upon the completeness, accuracy, or
          existence of any advertising, products or other materials on, or made
          available from, any External Sites.
        </p>
        <br />
        <p>10. Changes to the Applications</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          We may revise and update these Terms of Use from time to time at our
          sole discretion. When we make changes, we will make the updated Terms
          available on the Applications and update the “Last Updated” date at
          the beginning of these Terms accordingly, which apply to all access to
          and use of the Applications and Website thereafter. Please check these
          Terms periodically for changes. Any changes to the Terms will apply on
          the date that they are made, and your continued access to or use of
          the Applications after the Terms have been updated will constitute
          your binding acceptance of the updates. If you do not agree to any
          revised Terms, you may not access or use the Applications.
        </p>
        <br />
        <p>11. Children</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          You affirm that you are over the age of 13, as the applications are
          not intended for children under 13 and you may not access the
          applications if you are under the age of 13. If you are 13 or older
          but under the age of 18, or the legal age of majority where you reside
          if that jurisdiction has an older age of majority, then you agree to
          review these terms with your parent or guardian to make sure that both
          you and your parent or guardian understand and agree to these terms.
          You agree to have your parent or guardian review and accept these
          terms on your behalf. If you are a parent or guardian agreeing to the
          terms for the benefit of a child over 13, then you agree to and accept
          full responsibility for that child’s use of the app, including all
          financial charges and legal liability that he or she may incur.
        </p>
        <br />
        <p>12. Privacy Policy</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          Our Privacy Policy describes the ways we collect, use, store, and
          disclose your personal information, and is hereby incorporated by this
          reference into these Terms. You agree to the collection, use, storage,
          and disclosure of your data in accordance with our Privacy Policy
          which is available at{" "}
          <a
            href="https://dfiance.com/privacy-policy"
            style={{ color: "black", textDecoration: "underline" }}
          >
            https://dfiance.com/privacy-policy
          </a>
        </p>
        <br />
        <p>13. Dispute Resolution: Arbitration</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          Please read this section carefully. It may significantly affect your
          legal rights, including your right to file a lawsuit in court. Except
          for disputes that qualify for small claims court, all disputes arising
          out of or related to these terms of use or any aspect of the
          relationship between you and us, whether based in contract, tort,
          statute, fraud, misrepresentation, or any other legal theory, will be
          resolved through final and binding arbitration before a neutral
          arbitrator instead of in a court by a judge or jury, and you agree
          that dfiance and you are each waiving the right to sue in court and to
          have a trial by a jury. You agree that any arbitration will take place
          on an individual basis; class arbitrations and class actions are not
          permitted and you are agreeing to give up the ability to participate
          in a class action.
        </p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          The laws of the capable courts of zug, switzerland shall govern these
          terms of services.
        </p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          Except for those disputes that shall be resolved in arbitration or in
          small claims court pursuant to this section, each party agrees to
          submit to the personal and exclusive jurisdiction of the courts
          located in zug, switzerland provided that any claims or disputes shall
          be subject to the arbitration provisions set forth below.
        </p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          Except for claims for injunctive or equitable relief or claims
          regarding intellectual property rights (which may be brought, in an
          individual capacity only, and not on a class-wide or representative
          basis, in the courts specified above), any dispute between you and
          dfiance related in any way to, or arising in any way from, our privacy
          policy or these terms of services (“dispute”) shall be finally settled
          on an individual, non-representative basis in binding arbitration in
          accordance with the Swiss arbitration association (“asa”) rules for
          arbitration of consumer-related disputes (available from asa on its
          website at https://www.Swissarbitration.Org/), as modiﬁed by this
          agreement or in accordance with rules on which we may mutually agree;
          provided, however, that to the extent a dispute is within the scope of
          a small claims court’s jurisdiction, either you or dfiance may
          commence an action in small claims court, in the county (or
          equivalent) of your most recent physical address, to resolve the
          dispute.
        </p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          Any arbitration will be conducted by a single, neutral arbitrator and
          shall take place in zurich, switzerland. The arbitrator may award any
          relief that a court of competent jurisdiction could award, including
          attorneys’ fees when authorized by law. The arbitral decision may be
          enforced in any court of competent jurisdiction.
        </p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          The arbitrator will conduct hearings, if any, by teleconference or
          videoconference, rather than by personal appearances, unless the
          arbitrator determines upon request by you or by us that an in-person
          hearing is appropriate. Any in-person appearances will be held at a
          location that is reasonably convenient to both parties with due
          consideration of their ability to travel and other pertinent
          circumstances. If the parties are unable to agree on a location, such
          a determination should be made by asa or by the arbitrator. The
          arbitrator’s decision will follow the terms of these terms of use and
          will be final and binding. The arbitrator will have the authority to
          award temporary, interim, or permanent injunctive relief or relief
          providing for the specific performance of these terms of use, but only
          to the extent necessary to provide relief warranted by the individual
          claim before the arbitrator. The award rendered by the arbitrator may
          be confirmed and enforced in any court having jurisdiction thereof.
          Notwithstanding any of the foregoing, nothing in these terms of use
          will preclude you from bringing issues to the attention of the state,
          or local agencies and, if the law allows, they can seek relief against
          us for you.
        </p>
        <br />
        <p>14. Waiver and Severability</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          No waiver by the Dfiance of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>
        <br />
        <p>15. Content Standards</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          These content standards apply to any and all contributions and use by
          you to the Applications or Websites. Your use and contributions must
          in their entirety comply with all applicable federal, state, local,
          and international laws and regulations. Without limiting the
          foregoing, You must not:
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.22&nbsp;&nbsp;Provide any material that is defamatory, obscene,
          indecent, abusive, offensive, harassing, violent, hateful,
          inflammatory, or otherwise objectionable.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.23&nbsp;&nbsp;Promote sexually explicit or pornographic material,
          violence, or discrimination based on race, sex, religion, nationality,
          disability, sexual orientation, or age.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.24&nbsp;&nbsp;Infringe any patent, trademark, trade secret,
          copyright, or other intellectual property or other rights of any other
          person.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.25&nbsp;&nbsp;Violate the legal rights (including the rights of
          publicity and privacy) of others or contain any material that could
          give rise to any civil or criminal liability under applicable laws or
          regulations or that otherwise may be in conflict with these Terms of
          Use and our Privacy Policy.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.26&nbsp;&nbsp;Be likely to deceive any person.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.27&nbsp;&nbsp;Promote any illegal activity, or advocate, promote, or
          assist any unlawful act.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.28&nbsp;&nsbp;Impersonate any person, or misrepresent your identity
          or affiliation with any person or organization.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.29&nbsp;&nbsp;Involve commercial activities or sales, such as
          contests, sweepstakes, and other sales promotions, barter, or
          advertising.
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          1.30&nbsp;&nbsp;Give the impression that they emanate from or are
          endorsed by us or any other person or entity if this is not the case.
        </p>
        <br />
        <p>16. Not an Investment Company</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          Dfiance is not registered as an “investment company” under the
          Investment Company Act of 1940, as amended, or under equivalent laws
          of any other jurisdiction.
        </p>
        <br />
        <p>17. Defamation</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          You agree you will not disparage or criticize Dfiance or its
          Affiliates, or their respective businesses, management, directors,
          business practices, or equity holders (the “Company Entities”) and
          that you will not otherwise do or say anything that could disrupt the
          good morale, or otherwise harm the interests or reputations, of the
          Company Entities and we agree we will not publicly disparage or
          criticize you.
        </p>
        <br />
        <p>18. General</p>
        <br />
        <p style={{ marginLeft: "40px" }}>
          These Terms constitute the entire legal agreement between you and
          Dfiance, govern your access to and use of the Applications, and
          completely replace any prior or contemporaneous agreements between the
          parties related to your access to or use of the Applications, whether
          oral or written. There are no third-party beneficiaries to these
          Terms. The parties are independent contractors, and nothing in these
          Terms creates any agency, partnership, or joint venture. The language
          in these Terms will be interpreted as to its fair meaning, and not
          strictly for or against any party. You may not assign any of your
          rights or obligations under these Terms, whether by operation of law
          or otherwise, without our prior written consent. We may assign our
          rights and obligations under these Terms in our sole discretion to an
          affiliate, or in connection with an acquisition, sale, or merger.
          Should any part of these Terms be held invalid or unenforceable, that
          portion shall be construed consistent with applicable law and the
          remaining portions will remain in full force and effect. Our failure
          to enforce any provision of these Terms will not be deemed a waiver of
          such provision, nor of the right to enforce such provision. These
          Terms will be governed by and construed in accordance with the laws of
          Zug, Switzerland. We will not be liable for any failure or delayed
          performance of our obligations that result from any condition beyond
          our reasonable control, including, but not limited to, governmental
          action, acts of terrorism, earthquake, fire, flood, acts of God, labor
          conditions, power failures, Internet disturbances, or acts or
          omissions of third parties. You agree that we may provide you with
          notices (including, without limitation those regarding changes to
          these Terms) by email, regular mail, or postings on the Applications.
          By providing us with your email address, you consent to our using the
          email address to send you any notices required by law in lieu of
          communication by postal mail.
        </p>
        <br />
        <br />
        <br />
        <br />
      </p>
    </div>
  );
};

export default Terms;
