import React from "react";
/**
 * @function (PrivacyPolicy) - renderss Privacy Policy page. 
 * @returns PrivacyPolicy function on application
 */
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <h1 style={{ fontSize: "35px" }}>Privacy Policy</h1>
      <br />
      <p>Last Updated: February 2023</p>
      <br />
      <p>
        Wings Stiftung ("Wings", the “Company”, "we", or "us") operates the
        website.
      </p>
      <br />
      <p>
        The below reflects our internal policies in regard to the collection,
        use, and disclosure of personal data we collect when you use our
        Service(s), as well as the options you have associated with your data.
      </p>
      <br />
      <p>
        Note that this document may be periodically updated. If at any time you
        decide not to continue allowing the Company to use your data in
        accordance with the policy reflected in this document, you may request
        the Company to remove your data.
      </p>
      <br />
      <p>
        The Company uses your data to provide and improve the Service(s). By
        using a Service provided by the Company, you agree to the collection and
        to the use of the collected information in accordance with this
        document.{" "}
      </p>
      <br />
      <p>1. INFORMATION COLLECTION AND USE</p>
      <br />
      <p>
        The Company collects different types of information for the purpose of
        providing you with the Service(s) and improving its Service(s). Note
        that if you do not use any Service(s) for a consecutive period of 24
        months, the Company will automatically delete all your personal data.
        The Company collects the following data:
        <br />
        <br />
        <p style={{ marginLeft: "25px", wordSpacing: "15px" }}>
          1.1 Personal Data
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          While using our Service(s), we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <br />
        <li style={{ marginLeft: "50px" }}>Email Address</li>
        <li style={{ marginLeft: "50px" }}>
          Discord or Telegram username or phone number
        </li>
        <li style={{ marginLeft: "50px" }}>Cookies and Usage Data</li>
        <li style={{ marginLeft: "50px" }}>Wallet address</li>
        <br />
        <p style={{ marginLeft: "25px", wordSpacing: "15px" }}>
          1.2 Activity & Usage Data
        </p>
        <br />
        <div style={{ marginLeft: "50px" }}>
          <li>
            We may also collect information sent by your browser whenever you
            access our Service(s) through a computer or when you access the
            Service by or through a mobile device ("Usage Data").
          </li>
          <br />
          <li>
            This Usage Data may include information such as your computer's
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service(s) that you visit, the time and
            date of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </li>
          <br />
          <li>
            When you access the Service by or through a mobile device, this
            Usage Data may include information such as the type of mobile device
            you use, your mobile device's unique ID, the IP address of your
            mobile device, your mobile operating system, the type of mobile
            Internet browser you use, unique device identifiers and other
            diagnostic data.
          </li>
        </div>
        <br />
        <p style={{ marginLeft: "25px", wordSpacing: "15px" }}>
          1.3 Tracking Cookies Data
        </p>
        <br />
        <p style={{ marginLeft: "70px" }}>
          What are cookies: Cookies are files with a small amount of data that
          may include an anonymous unique identifier. Cookies are sent to your
          browser from a website and stored on your device. Tracking
          technologies also used are beacons, tags, and scripts to collect and
          track information and to improve and analyze our Service(s).
        </p>
        <br />
        <div className="list">
          <p>1.3.1</p>
          <p style={{ marginLeft: "75px" }}>
            We use cookies and similar tracking technologies to track the
            activity on our Service(s) and may store certain information. You
            can instruct your browser to refuse all cookies or to indicate when
            a cookie is being sent, please note however that if you do not
            accept cookies, you may not be able to use some or all of our
            Service(s). Examples of cookies we use, but not limited to:
          </p>
        </div>
        <br />
        <div className="sub-list">
          <li>
            Session Cookies. We use Session Cookies to operate our Service(s).
          </li>
          <li>
            Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings in regard to our Service(s).
          </li>
          <li>
            Security Cookies. We use Security Cookies for security purposes.
          </li>
        </div>
        <br />
        <p>2. USE OF DATA</p>
        <br />
        <p>The Company uses the collected data for various purposes:</p>
        <br />
        <div style={{ marginLeft: "70px" }}>
          <li>To provide and maintain the Service(s)</li>
          <li>To notify you about changes to our Service(s)</li>
          <li>
            To allow you to participate in interactive features of our
            Service(s) when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service(s)
          </li>
          <li>To monitor the usage of the Service(s)</li>
          <li>
            To detect, prevent and address technical issues and potential
            attempts of manipulation of our Service(s)
          </li>
        </div>
        <br />
        <p>3. TRANSFER OF DATA</p>
        <br />
        <div style={{ marginLeft: "20px" }}>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country, or other governmental jurisdiction where the data
            protection laws may differ from those from your jurisdiction. If you
            are located outside the United States and choose to provide
            information to us, please note that we may transfer the data,
            including Personal Data, to the United States and may process it
            there. Your consent to this Privacy Policy followed by your
            submission of such information represents your agreement to that
            transfer.
          </p>
          <br />
          <p>
            The Company will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>
        </div>
        <br />
        <p>4. CLOSURE OF DATA</p>
        <br />
        <p style={{ marginLeft: "20px" }}>Legal Requirement</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          The Company may disclose your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <br />
        <div style={{ marginLeft: "70px" }}>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of the Company</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service(s)
          </li>
          <li>
            To protect the personal safety of users of the Service(s) or the
            public
          </li>
          <li>To protect against legal liability</li>
        </div>
        <br />
        <p>5. SECURITY OF DATA</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <br />
        <p>6. SERVICE PROVIDERS</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          We may employ third-party companies and individuals to facilitate our
          Service(s) ("Service Providers"), to provide the Service(s) on our
          behalf, to perform Service-related services, or to assist us in
          analyzing how our Service(s) are used. These third parties have access
          to your Personal Data only to perform these tasks on our behalf and
          are obligated not to disclose or use it for any other purpose.
          <br />
          <strong>Analytics</strong> - We may use third-party Service Providers
          to monitor and analyze the use of our Service(s).
        </p>
        <br />
        <p>7. LINKS TO OTHER SITES</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third-party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>
        <br />
        <p>8. CHILDREN'S PRIVACY</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          Our Service(s) does not address anyone under the age of 18
          ("Children").
        </p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and know
          that your Children have provided us with Personal Data, please contact
          us. If we become aware that we have collected Personal Data from
          children without verification of parental consent, we take steps to
          remove that information from our servers.
        </p>
        <br />
        <p>9. CHANGES TO THIS PRIVACY POLICY</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective, and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <br />
        <p>10. CONTACT US</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <br />
        <p style={{ marginLeft: "20px" }}>Email: privacy_policy@dfiance.com</p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          If you would like to know what kind of information we are storing
          regarding your user, please email privacy_policy@dfiance.com and use
          the subject “SAR: user”
        </p>
        <br />
        <p style={{ marginLeft: "20px" }}>
          If you would like your personal data deleted, please email
          privacy_policy@dfiance.com from the registered email address and use
          the subject: “Delete:User”.
        </p>
        <br />
        <p style={{ paddingBottom: "20px" }}>
          Unless otherwise defined in this Privacy Policy, terms used in this
          Privacy Policy have the same meanings as in our Terms and Conditions.
        </p>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
