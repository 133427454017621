import { useRef, useEffect } from "react";

import { withServices, ServiceContainer } from "../../hocs/withServices";

import { Deck } from "../../components/lists/deck";
/**
 * Interface for the props object that is passed to the DeckSection component.
 * @typedef {Object} DeckSectionProps
 * @property {ServiceContainer} serviceContainer - An object that contains services needed for the component.
 */
interface DeckSectionProps {
  serviceContainer: ServiceContainer;
}
/**
 * A functional component that renders a deck section and takes in a DeckSectionProps props object.
 * @param {DeckSectionProps} props - The props object that contains a serviceContainer property.
 * @param {ServiceContainer} props.serviceContainer - An object that contains services needed for the component.
 * @returns {JSX.Element} - The JSX element that represents the rendered deck section.
 */
const DeckSection = (props: DeckSectionProps) => {
  const { serviceContainer } = props;
  const background = useRef(null);
  const section = useRef<HTMLDivElement>(null);
  const url = window.location.href;
  const parts = url.split("/");
  const refVar = parts[parts.length - 1];
  /**
   * A React hook that adds references to the image loader in the serviceContainer and sets an animation.
   * @param {Object} serviceContainer - An object that contains services needed for the component.
   * @param {Object} background - The background object that needs to be loaded.
   * @param {Object} section - The section object that needs to be loaded.
   */
  useEffect(() => {
    const animation = document.getElementById("deckAnimation");
    animation?.classList.add("paused");
    serviceContainer.imageLoader.addRef(background, (load) => {
      if (load) {
        return {
          id: "deckContainer",
          urls: {
            desk: require("../../assets/img/home/goldenFog.png").default,
            /*mob: null && [
              require("../../assets/img/home/mob/mobThrone2.png").default,
              require("../../assets/img/home/mob/mobThrone3.png").default,
            ],*/
          },
        };
      }
    });
    serviceContainer.imageLoader.addRef(section, (visible) =>
      visible
        ? animation?.classList.remove("paused")
        : animation?.classList.add("paused")
    );
  }, [serviceContainer.imageLoader]);

  return (
    <section
      className="homeSection deckSection ghostBg"
      id="deckSection"
      ref={section}
    >
      <div
        className="sectionContainer deckContainer"
        id="deckContainer"
        ref={background}
      >
        <h3>ARE YOU READY?</h3>
        <h2>
          <span>Join the fight</span>
        </h2>
        <p>
          Be cunning, choose your strategy wisely, destroy your opponents and
          become the alpha leader. Do you have what it takes?
        </p>
        <a
          // onClick={() =>
          // document
          //     .querySelector("#portal .signUpContainer")
          //     ?.classList.add("visible")
          // }
          href={`${process.env.REACT_APP_PLAY_BUTTON_URL}${
            refVar ? "?r=" + refVar : ""
          }`}
          target={"_blank"}
        >
          <button className="volButton">Play For Free</button>
        </a>
        <Deck />
      </div>
      <div className="ruggedLine"></div>
    </section>
  );
};

export default withServices(DeckSection);
