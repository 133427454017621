// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {
  ReactChild,
  ReactChildren,
  Children,
  isValidElement,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import { ITestimonial } from "../elements/testimonial";

import { IFeatureCard } from "../elements/feature";

import { IMechanicPoint } from "../lists/mechanics";

import goldenCard from "../../assets/img/home/sliderCardGolden.png";
import { useWindowSize } from "../../hooks/useSize";
import Battlefield from "../../store/models/battlefield";
import { mechanisPoints } from "../../mockData";
/**
 * SliderCard component for displaying child components with additional props
 * @param {Object} props - Props object
 * @param {ReactChild|ReactChildren} props.children - Child components to be rendered
 * @param {IFeatureCard|ITestimonial|IMechanicPoint} props.card - Data object to be passed as prop to child components
 * @param {number} props.index - Index of the data object being passed as prop to child components
 * @returns {JSX.Element} - React element
 */
const SliderCard = ({
  children,
  card,
  index,
}: {
  children: ReactChild | ReactChildren;
  card: IFeatureCard | ITestimonial | IMechanicPoint;
  index: number;
}) => {
  /**
   * Maps over children components and returns them with additional props
   * @param {ReactChild} child - Child component being mapped over
   * @returns {ReactChild} - Child component with additional props
   */
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement<any>(child, { card, index });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};
/**
 * SliderCards component for displaying a slider of cards
 * @param {Object} props - Props object
 * @param {IFeatureCard[]|ITestimonial[]|IMechanicPoint[]} props.cards - Array of data objects to display as cards
 * @param {function} props.render - Function to render the selected card
 * @param {number} props.selectedCard - Index of the currently selected card
 * @param {function} props.setCard - Function to set the index of the currently selected card
 * @returns {JSX.Element} - React element
 */
export const SliderCards = ({
  cards,
  render,
  selectedCard,
  setCard,
}: {
  cards: IFeatureCard[] | ITestimonial[] | IMechanicPoint[];
  render: any;
  selectedCard: number;
  setCard: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const data = cards;

  const battlefield = Battlefield();
  const { width } = useWindowSize();
  /**
   * Hover on event handler for card hover
   * @typedef {function}
   */
  const onHoverOn = useCallback(battlefield.hoverHandler(), [width]);
  /**
   * Hover off event handler for card hover
   * @typedef {function}
   */
  const onHoverOff = useCallback(battlefield.hoverOffHandler(), [width]);

  useEffect(() => {
    onHoverOn("", "Deployable Cards");
  }, []);
  /**
   * Click event handler for slider card buttons
   * @param {number} denom - The increment to apply to the selectedCard index
   * @param {any} e - The click event
   */
  const onClickSliderCardButton = (denom: number, e: any) => {
    let newIdx = selectedCard + denom;

    if (newIdx < 0) {
      newIdx = data.length - 1;
    }

    if (newIdx > data.length - 1) {
      newIdx = 0;
    }

    setCard(newIdx);

    for (const ghost of mechanisPoints) {
      if (mechanisPoints[newIdx]?.title === ghost?.title) {
        onHoverOn(e, ghost.title);
      }
      if (mechanisPoints[newIdx].title !== ghost.title) {
        onHoverOff(e, ghost.title);
      }
    }
  };

  return (
    <div className="container">
      <div
        className={"sliderCardButton sliderCardButton-prev fancyArrow"}
        onClick={(e) => onClickSliderCardButton(-1, e)}
      />
      {render(selectedCard)}
      <div
        className="sliderCardButton sliderCardButton-next fancyArrow"
        onClick={(e) => {
          onClickSliderCardButton(1, e);
        }}
      />
    </div>
  );
};
/**
 * FeatureSliderCards component that renders a slider of feature cards or testimonials.
 * @param {Object} props - Component props
 * @param {IFeatureCard[] | ITestimonial[]} props.data - An array of feature cards or testimonials to render in the slider.
 * @param {ReactChild | ReactChildren} props.children - Child components to be rendered inside each slider card.
 * @returns {JSX.Element} - Rendered FeatureSliderCards component.
 */
export const FeatureSliderCards = ({
  data,
  children,
}: {
  data: IFeatureCard[] | ITestimonial[];
  children: ReactChild | ReactChildren;
}) => {
  const [selectedCard, setCard] = useState(0);
  /**
   * Renders the slider component with the selected card.
   * @param {number} selectedCard - The index of the currently selected card.
   * @returns {JSX.Element} - Rendered slider component.
   */
  const render = (selectedCard: number) => {
    return (
      <div className="sliderCardsWrapper">
        <img src={goldenCard} alt="goldenCard-1" className="underbelly" />
        <img
          src={goldenCard}
          alt="goldenCard-2"
          className="underbelly reversed"
        />
        <div className="sliderCards">
          {data.map((card, index) => {
            const selected = selectedCard === index;
            return (
              <div className={`sliderCard ${selected ? "selected" : ""}`}>
                <SliderCard card={card} index={index} key={index}>
                  {children}
                </SliderCard>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <SliderCards
      cards={data}
      render={render}
      selectedCard={selectedCard}
      setCard={setCard}
    />
  );
};
/**
 * A component that renders a slider of mechanic points with their respective title and text
 * @param {Object} props - The component props
 * @param {Array<IMechanicPoint>} props.data - An array of mechanic points to be displayed in the slider
 * @param {React.LegacyRef<HTMLDivElement>} props.imageRef - A reference to the div element that contains the slider image
 * @param {number} props.selectedCard - The index of the currently selected card in the slider
 * @param {React.Dispatch<React.SetStateAction<number>>} props.setCard - A function to update the currently selected card index
 * @returns {JSX.Element} - The JSX code for the MechanicSliderCards component
 */
export const MechanicSliderCards = ({
  data,
  imageRef,
  selectedCard,
  setCard,
}: {
  data: IMechanicPoint[] | [];
  imageRef: React.LegacyRef<HTMLDivElement>;
  selectedCard: number;
  setCard: React.Dispatch<React.SetStateAction<number>>;
}) => {
  /**
   * Renders the slider with the provided data
   * @param {number} selectedCard - The index of the currently selected card in the slider
   * @returns {JSX.Element} - The JSX code for the slider with the selected card
   */
  const render = (selectedCard: number) => (
    <div ref={imageRef} id="mechanicPoints" className="mechanicPoints">
      {data.map((card, index) => (
        <div
          className={`mech flexCol ${selectedCard === index ? "selected" : ""}`}
        >
          <SliderCard card={card} index={index} key={index}>
            <>
              <h2>{card.title}</h2>
              <p>{card.text}</p>
            </>
          </SliderCard>
        </div>
      ))}
    </div>
  );

  return (
    <div className="mechPointsContainer">
      <SliderCards
        cards={data}
        render={render}
        selectedCard={selectedCard}
        setCard={setCard}
      />
    </div>
  );
};
