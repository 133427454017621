import React from "react";

import { ICard } from "../../../store/types";

import { useWindowSize } from "../../../hooks/useSize";
import { cardRarityData } from "../../../mockDataRarity";
/**
 * Component that displays information about a card
 * @param {Object} props - Component props
 * @param {ICard} props.card - The card to display information about
 * @returns {JSX.Element} - The rendered component
 */
export const CardInfo = ({ card }: { card: ICard }) => {
  const { width } = useWindowSize();
  const mobile = Number(width) <= 480;
  const filteredData: string = card?.Quote_EN?.replace(/^\*.+/, "");
  const rarityPoints = cardRarityData
    .filter((x) => x.rarity === card.Rarity)
    .map((x) => x.points);
  return (
    <div className="deckCard-infoCard">
      {mobile && <div className="deckCard-infoCard-Close"></div>}
      <div className="deckCard-infoCard-textWrapper">
        {!mobile && <div className="deckCard-infoCard-Close"></div>}
        <h5>{card.Type}</h5>
        <h3>{card.Name_EN}</h3>
        <q>{filteredData}</q>
        <p>{card.Description_EN}</p>
        {card.Ability && (
          <>
            <div className="deckCard-infoCard-ability">
              Ability<b>:</b>
              <span>{card.Ability}</span>
              <div className="deckCard-infoCard-SpellPic">
                <div className={`cardSpellShutter ${card.Rarity || ""}`}>
                  <div
                    className={`cardSymbol spellSymbol ${card.Ability || ""}`}
                  ></div>
                </div>
              </div>
            </div>
            <p>{card.abilityData?.Description}</p>
          </>
        )}

        <div className="deckCard-infoCard-stats">
          <div className="level">
            Level<b>:</b>
            <span>1</span>
          </div>

          <div className="exp">
            XP<b>:</b>
            <span className="left">500</span> <b>/</b>
            <span className="right">500</span>
          </div>
        </div>

        <div className="deckCard-infoCard-rarity">
          Rarity<b>:</b>
          <span>{card.Rarity}</span>
        </div>
        <div className="deckCard-infoCard-dimonds">
          {/* Renders a set of power diamonds to represent card rarity points.
          @param {number[]} rarityPoints - The array of rarity points for the card.
          @returns The component representing the set of power diamonds. */}
          {[...new Array(9).keys()].slice(1).map((idx: number) => {
            return (
              <div className="dimondWrapper" key={`dimond-${idx}`}>
                <div
                  className={`powerDimond ${
                    idx <= rarityPoints[0] ? "" : "empty"
                  }`}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
