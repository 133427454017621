import React, { useRef, useEffect, useState } from "react";

import { introductions } from "../../mockData";

import { VolButton } from "../elements/misc";

import { withServices, ServiceContainer } from "../../hocs/withServices";

import { isSafari, iOS } from "../../utils";
/**
 * Represents an introduction section of the page with an optional video, picture, and effect.
 * @interface
 * @property {string} picUrl - The URL of the picture to display in the introduction.
 * @property {string} title - The title of the introduction.
 * @property {string} text - The text to display in the introduction.
 * @property {string} [vidUrl] - The URL of an optional video to display in the introduction.
 * @property {string} [effectPic] - The URL of an optional picture effect to display in the introduction.
 * @property {string} [effectVid] - The URL of an optional video effect to display in the introduction.
 */
export interface Introduction {
  picUrl: string;
  title: string;
  text: string;
  vidUrl?: string;
  effectPic?: string;
  effectVid?: string;
}
/**
 * A component that displays an introduction card with an image, a title, and a description
 * @param {object} props - The props that are passed to the component
 * @param {Introduction} props.card - An object containing information about the introduction card, including an image URL, title, and text
 * @param {number} props.index - The index of the introduction card in the list of all introduction cards
 * @param {boolean} props.isLast - A boolean value indicating whether the current card is the last card in the list of introduction cards
 * @param {ServiceContainer} props.serviceContainer - An object containing references to various services used by the component
 */
const Introduction = withServices(
  ({
    card: { effectPic, effectVid, picUrl, vidUrl, title, text },
    index,
    isLast,
    serviceContainer,
  }: {
    card: Introduction;
    index: number;
    isLast: boolean;
    serviceContainer: ServiceContainer;
  }) => {
    const [intersected, setIntersected] = useState(false);

    const odd = index % 2 === 0;

    const image = useRef(null);
    /**
     * Loads the introduction image when it intersects with the viewport.
     * @param {object} serviceContainer - An object containing service instances.
     * @param {object} image - A reference to the introduction image.
     * @param {function} setIntersected - A function to set the state of whether the introduction image has intersected with the viewport.
     */
    useEffect(() => {
      serviceContainer.imageLoader.addRef(image, setIntersected);
    }, [serviceContainer.imageLoader]);

    let effectRender: any;
    let mediaRender: any;
    /**
     * Renders the media for the Introduction component based on the video URL and the device/browser capabilities.
     * If the video URL is not available, it will render an image.
     * If the browser is Safari or the device is an iOS device, it will render an image due to video limitations.
     * @param {string} picUrl - The URL of the image to be used as fallback or if video is not supported.
     * @param {string | undefined} vidUrl - The URL of the video to be displayed.
     * @param {boolean} intersected - A flag indicating if the element is intersecting with the viewport.
     * @returns {JSX.Element} - The JSX element that will be rendered based on the device/browser capabilities.
     */
    if (isSafari || iOS() || !vidUrl) {
      mediaRender = (
        <img
          src={intersected ? picUrl : "#"}
          alt={`introductionCard-${index}`}
          className="introductionCard-picture"
        />
      );
    } else {
      mediaRender = (
        <video
          autoPlay
          playsInline
          muted
          loop
          className="introductionCard-video"
        >
          <source src={vidUrl} />
        </video>
      );
    }

    if ((isSafari || iOS()) && effectPic) {
      effectRender = (
        <div className="introductionCard-pictureEffect pictureEffect"></div>
      );
    } else if (effectVid) {
      effectRender = (
        <video autoPlay playsInline muted loop className="blazeAnimation">
          <source src={effectVid} />
        </video>
      );
    }

    return (
      <div
        className={`introductionCard flexRow ${odd ? "oddItem" : ""}`}
        ref={image}
      >
        <div
          className={`pictureWrapper ${effectPic ? "pure" : ""} ${
            isSafari || iOS() ? "ios" : ""
          }`}
        >
          {intersected && mediaRender}
          {intersected && effectRender}
        </div>
        <div className="cardText">
          <h3>{title}</h3>
          <p>{text}</p>
          {isLast && <VolButton classes="red" />}
        </div>
      </div>
    );
  }
);
/**
 * This functional component displays a list of introductions using the data from introductions variable.
 * It receives props as a parameter, including a serviceContainer object of type ServiceContainer.
 * The component also uses a useRef hook to reference the bg element.
 */
export const Introductions = withServices((props) => {
  const data = introductions;
  const { serviceContainer }: { serviceContainer: ServiceContainer } = props;

  const bg = useRef(null);
  /**
   * This useEffect hook sets up an image loading reference using the serviceContainer object's imageLoader property.
   * It receives the bg reference as a parameter and a callback function to handle the image loading.
   * The callback function returns an object containing an id and a urls property, which specifies the image's source URL for the "desk" viewport size.
   * The useEffect hook runs whenever the serviceContainer.imageLoader property changes.
   */
  useEffect(() => {
    serviceContainer.imageLoader.addRef(bg, (load) => {
      if (load) {
        return {
          id: "introductonSection",
          urls: {
            desk: require("../../assets/img/home/introGhostBg.png").default,
          },
        };
      }
    });
  }, [serviceContainer.imageLoader]);

  return (
    <div className="introductionCards" ref={bg}>
      {data.map((card, index) => (
        <Introduction
          card={card}
          key={index}
          index={index}
          isLast={index === data.length - 1}
        />
      ))}
    </div>
  );
});
