import React, { useRef, useEffect, useMemo, useState } from "react";

import { mechanics, mechanisPoints } from "../../mockData";

import { withServices, ServiceContainer } from "../../hocs/withServices";

//import { SliderCards } from "./sliderCards";

import { useWindowSize } from "../../hooks/useSize";

import BattefieldBoard from "../board/battlefield";

import { MechanicSliderCards } from "../lists/sliderCards";
/**
 * Interface for Mechanics Slider Cards
 * @interface
 * @property {string} picUrl - The image url for mechanic cards.
 * @property {Object} points - The array of object having properties title and text
 */
export interface IMechanic {
  picUrl: string;
  points: {
    title: string;
    text: string;
  }[];
}
/**
 * Interface Mechanic Points
 * @interface
 * @property {string} title - The title of the mechanics points.
 * @property {string} text - The text of the mechanics points.
 */
export interface IMechanicPoint {
  title: string;
  text: string;
}

const Mechanic = withServices(
  ({
    //card,
    //index,
    serviceContainer,
  }: {
    card: IMechanic;
    index: number;
    serviceContainer: ServiceContainer;
  }) => {
    const image = useRef(null);
    const [selectedCard, setCard] = useState(0);
    const { width } = useWindowSize();

    const isMobile = useMemo(() => width && width <= 800, [width]);

    const setSliderIndexOnClickBattleField = (index: number) => {
      setCard(index);
    };

    useEffect(() => {
      serviceContainer.imageLoader.addRef(image, (load) => {
        if (load) {
          return {
            id: "mechanicPoints",
            urls: {
              desk: require("../../assets/img/card/woodBackground.png").default,
            },
          };
        }
      });
    }, [serviceContainer.imageLoader]);

    return (
      <div className={`mechanicCard flexCol`}>
        <div className={isMobile ? "container" : ""}>
          <div className="gameMechanicsPage noselect">
            <BattefieldBoard
              setSliderIndexOnClickBattleField={
                setSliderIndexOnClickBattleField
              }
            />
          </div>
        </div>

        <MechanicSliderCards
          data={mechanisPoints}
          imageRef={image}
          selectedCard={selectedCard}
          setCard={setCard}
        />
      </div>
    );
  }
);

export const Mechanics = withServices((props) => {
  const data: IMechanic[] = mechanics;

  const { serviceContainer }: { serviceContainer: ServiceContainer } = props;

  const background = useRef(null);

  useEffect(() => {
    serviceContainer.imageLoader.addRef(background, (load) => {
      if (load) {
        return {
          id: "mechanicSection",
          urls: {
            desk: require("../../assets/img/home/mechanicsGhostBg.png").default,
          },
        };
      }
    });
  }, [serviceContainer.imageLoader]);

  return (
    <div className="mechanicCards" ref={background}>
      {data.map((item, index) => (
        <Mechanic card={item} key={index} index={index} />
      ))}
    </div>
  );
});
