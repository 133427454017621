import { ethers } from "ethers";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as MetamaskSVG } from "../../assets/img/icons/metamaskI.svg";
import { ServiceContainer, withServices } from "../../hocs/withServices";
import { createUser, setUserToken } from "../../store/reducer/users";
import { IUser } from "../../store/types";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { useWindowSize } from "../../hooks/useSize";

type State = {
  walletAddress: string;
  emailAddress: string;
  marketingEmails: boolean;
};

export const SignUp = withServices(
  ({
    serviceContainer,
    id,
  }: {
    serviceContainer: ServiceContainer;
    id?: string;
  }) => {
    const [state, setState] = useState<State>({
      walletAddress: "",
      emailAddress: "",
      marketingEmails: false,
    });
    const [isConnected, setConnectedAccount] = useState(false);
    const [isValid, setValid] = useState(false);
    const [errorValidity, setErrorValidity] = useState("");
    const [isSignUp, setSignUp] = useState(false);
    const [, setChecked] = useState(false);
    const [referralCodeStatus, setReferralCodeStatus] = useState("true");
    const { width } = useWindowSize();
    const isMobile = useMemo(() => Number(width) <= 480, [width]);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryString = Object.fromEntries(urlSearchParams.entries());
    const userAgent = navigator.userAgent;
    let browserName: string;
    let finalOS: string;
    let deviceType = navigator.userAgent;
    const dispatch = useDispatch();
    /*
     * Validates a referral code and sets the referral code status.
     * @param {string} code - The referral code to validate.
     */
    const validateReferralCode = async (code: string) => {
      const res = await serviceContainer.api.validateReferralCode(code);
      setReferralCodeStatus(res.data.result);
    };
    /**
     * Validates the referral code if an id is provided.
     * @param {string} id - The referral code to validate.
     */
    if (id) {
      validateReferralCode(id);
    }
    /**
     * Function that will be working only for mobile devices to connect metamask using walletconnect.
     */
    const openWalletConnect = async () => {
      // Create a connector
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: QRCodeModal,
      });

      // Check if connection is already established
      if (!connector.connected) {
        // create new session
        connector.createSession();
      }

      // Subscribe to connection events
      connector.on("connect", (error, payload) => {
        if (error) {
          throw error;
        }
        // Get provided accounts and chainId
        const { accounts } = payload.params[0];
        setState({ ...state, walletAddress: accounts });
        postUser(accounts);
        setConnectedAccount(true);
      });

      connector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        // Get updated accounts and chainId
        const { accounts } = payload.params[0];
        setState({ ...state, walletAddress: accounts });
      });
      connector.on("disconnect", (error) => {
        if (error) {
          throw error;
        }

        // Delete connector
      });
    };
    /**
     * Handles the logic for connecting to a wallet.
     * It requests access to the user's MetaMask account and retrieves the signer to sign a message.
     * If successful, it sets the connected account state to true.
     * If the user does not have MetaMask installed, it shows an alert message.
     */
    const connectWalletHandler = async () => {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const message = "Connect to Dfiance";
        const signature = await signer.signMessage(message);
        console.log("signature", signature);

        if (window.ethereum && window.ethereum.isMetaMask) {
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then(async (result: any) => {
              await accountChangedHandler(result[0]);
              setConnectedAccount(true);
            })
            .catch((error: any) => {
              console.log(error.message);
            });
        } else {
          console.log("Need to install MetaMask");
        }
      } catch (ex) {
        if (!window.ethereum.isMetaMask) {
          alert("Need to install MetaMask");
        }
      }
    };

    /**
     * Handles changing of the connected account and updating the user's wallet address in the state and database.
     * @param {any} newAccount - The new account to be set as the connected account.
     * @returns {Promise<void>}
     */
    const accountChangedHandler = async (newAccount: any) => {
      setState({ ...state, walletAddress: newAccount });
      await postUser(newAccount);
    };

    /**
     * Handles changing of the email address input and sets its value to the state.
     * Validates the email address format and sets the validity state accordingly.
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input event that triggered the function.
     * @returns {void}
     */
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const email = event.target.value;
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setState({ ...state, emailAddress: email });
      if (re.test(event.target.value)) {
        return setValid(true);
      }
      return (
        setValid(false), setErrorValidity("*Please enter a valid email address")
      );
    };
    /**
     * Handles checking that if the checkbox is checked or not.
     * Validates the checkbox and sets the state accordingly.
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input event that triggered the function.
     */
    const handleCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const marketingEmails = event.target.checked;
      setState({ ...state, marketingEmails: marketingEmails });
    };

    //Detect Device Type
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(deviceType)) {
      deviceType = "Tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        deviceType
      )
    ) {
      deviceType = "Mobile";
    } else {
      deviceType = "Desktop";
    }

    //Detect browser
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "Google Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "Microsft Edge";
    } else {
      browserName = "No browser detection";
    }

    //Detect OS
    if (userAgent.search("Windows") !== -1) {
      finalOS = "Windows";
    } else if (userAgent.search("Mac") !== -1) {
      finalOS = "MacOS";
    } else if (userAgent.search("Android") !== -1) {
      finalOS = "Android";
    } else if (
      userAgent.search("X11") !== -1 &&
      !(userAgent.search("Linux") !== -1)
    ) {
      finalOS = "UNIX";
    } else if (
      userAgent.search("Linux") !== -1 &&
      userAgent.search("X11") !== -1
    ) {
      finalOS = "Linux";
    }
    /**
     * Posts a new user with the given user data and sets the user token in Redux state.
     * If a wallet address is not provided, the current wallet address from the Ethereum provider is used.
     * @param {string} walletAddress - The wallet address to associate with the new user (optional)
     * @returns {Promise<void>}
     */
    const postUser = async (walletAddress: string) => {
      const user: IUser = {
        Email: state.emailAddress,
        Wallet: state.walletAddress || walletAddress,
        Marketing_Emails: state.marketingEmails,
        BrowserName: browserName,
        OS_Name: finalOS,
        Device_Type: deviceType,
        Referral_Code: id || "",
      };
      const res = await serviceContainer.api.postUser(user);

      dispatch(createUser({ users: res.data.result.users }));
      dispatch(setUserToken(res.data.result.tokens));
      setState({ ...state, emailAddress: "" });
      document
        .querySelector("#portal .signUpContainer")
        ?.classList.remove("visible");
    };
    const emailInput = document.getElementById("emailField");
    const signInButton = document.getElementById("signUpPopupButton");
    if (signInButton) {
      signInButton.addEventListener("click", function () {
        if (state.emailAddress == "" && !isValid) {
          emailInput?.classList.add("shake");
        }
      });
      signInButton.removeEventListener("click", function () {
        emailInput?.classList.remove("shake");
      });
    }
    /**
     * Effect hook to check for wallet and email in localStorage, set states and check for verified query string
     * @function
     * @name useEffect
     * @param {string | null} localStorage.getItem("accessToken") - Access token from localStorage
     * @param {string} state.walletAddress - Wallet address from state
     * @returns {undefined}
     */
    useEffect(() => {
      if (localStorage.getItem("Wallet")) {
        setConnectedAccount(true);
        setState({
          ...state,
          walletAddress: localStorage.getItem("Wallet") ?? "",
        });
      }
      if (localStorage.getItem("Email")) {
        setSignUp(true);
        setState({
          ...state,
          emailAddress: localStorage.getItem("Email") ?? "",
        });
      }
      if (queryString.verified) {
        localStorage.setItem("isVerified", "true");
      }
    }, [localStorage.getItem("accessToken"), state.walletAddress]);

    return (
      <div className="signUp">
        {referralCodeStatus == "true" ? (
          <div>
            <h2>Dfiance open Beta will launch soon</h2>
            {!isConnected && !isSignUp ? (
              <p>
                Signup today and get a chance to be among the first to join the
                game, get airdrops, giveaways and more.
              </p>
            ) : (
              ""
            )}
            {isConnected && !isSignUp ? (
              <p>
                You are now eligible for airdrops. <br />
                Signup with your email to secure a spot in the Beta.
              </p>
            ) : (
              ""
            )}
            {isSignUp && !isConnected ? (
              <p>
                Meanwhile, connect your Metamask to also be eligible for
                airdrops!
              </p>
            ) : (
              ""
            )}
            {isSignUp && isConnected ? (
              <p>You’re all set for the upcoming Beta!</p>
            ) : (
              ""
            )}

            <div className="signUp-buttonsContainer flexCol">
              {!isConnected ? (
                <>
                  <button
                    onClick={() => {
                      !isMobile ? connectWalletHandler() : "";
                      isMobile ? openWalletConnect() : "";
                    }}
                    className="walletConnect flexCenter"
                  >
                    <MetamaskSVG />
                    Connect Metamask
                  </button>
                  <span>or</span>
                </>
              ) : (
                ""
              )}
              {isConnected ? (
                <>
                  <button className="walletConnected flexCenter" disabled>
                    Metamask Connected!
                  </button>
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
              {!isSignUp ? (
                <div className="emailForm flexRow">
                  <input
                    id="emailField"
                    type="email"
                    value={state.emailAddress}
                    // disabled={!isValid}
                    onChange={(email) => {
                      handleEmailChange(email);
                    }}
                    placeholder="Email Address"
                    required
                  />

                  <button
                    id="signUpPopupButton"
                    onClick={() => {
                      isValid ? postUser("") : "";
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              ) : (
                ""
              )}
              {(queryString.verified ||
                localStorage.getItem("isVerified") == "true") &&
              localStorage.getItem("Email") ? (
                <div>
                  <button disabled className="signedUp">
                    SIGNED UP!
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="emailForm flexRow">
                <span className="error_msg">
                  {!isValid && state.emailAddress?.length > 0
                    ? errorValidity
                    : ""}
                </span>
              </div>
              {!isSignUp ? (
                <div className="emailForm flexRow checkbox">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    onClick={() => setChecked(true)}
                  />
                  <span>
                    I approve to receive emails from Dfiance (Optional).
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            {isSignUp &&
            !queryString.verified &&
            localStorage.getItem("isVerified") !== "true" ? (
              <>
                <div className="verify">
                  <div className="verification"></div>
                  <p style={{ padding: "10px", fontSize: "15px" }}>
                    A confirmation link has been sent to your email. Please
                    click the link to confirm your registration.
                  </p>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="privacy-policy-div">
              <span className="privacy-policy">
                By signing up or connecting your wallet, you agree to the{" "}
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
                .
              </span>
            </div>
          </div>
        ) : (
          <h2>{referralCodeStatus}</h2>
        )}
      </div>
    );
  }
);
