import { IFeatureCard } from "./components/elements/feature";
import { Introduction } from "./components/lists/introduction";
import { IMechanic, IMechanicPoint } from "./components/lists/mechanics";
import { IFaction } from "./components/lists/factions";

import { IWorld } from "./components/lists/world";
import { IAllyCard } from "./components/lists/allies";
import { ITestimonial } from "../src/components/elements/testimonial";
import { IBacker } from "../src/components/lists/backers";
import { IDeckCard } from "../src/components/lists/deck";
import { IWiki } from "./pages/homeSections/wiki";

import { MapLocation } from "./store/types";

import feature1 from "./assets/img/lists/features/features1.png";
import feature2 from "./assets/img/lists/features/features2.png";
import feature3 from "./assets/img/lists/features/features3.png";
import feature4 from "./assets/img/lists/features/features4.png";

import intro1 from "./assets/img/lists/intorductions/intro1.png";
import intro2 from "./assets/img/lists/intorductions/intro2.png";
import intro2Vid from "./assets/animations/Choose your army and build your strategic skills.webm";
import intro3 from "./assets/img/lists/intorductions/intro3.png";
import intro3Vid from "./assets/animations/Collect resources to fuel your succes.webm";

import introEffectVid from "./assets/animations/glowing smoke_VP9.webm";
import introEffectImg from "./assets/img/lists/intorductions/introEffect4.png";

import mechanic1 from "./assets/img/lists/mechanics/mechanic1.png";

import infernal from "./assets/img/lists/factions/Infernal.png";
import Ephemeral from "./assets/img/lists/factions/Ephemeral.png";
import Celestial from "./assets/img/lists/factions/Celestial.png";
import Factionless from "./assets/img/lists/factions/Factionless.png";

import ethermeria from "./assets/img/lists/worlds/Ephemeria.png";

import guildwars from "./assets/img/lists/allies/Guild Wars Illustration.png";
import guild from "./assets/img/lists/allies/Guilds Illustration.png";
import tournaments from "./assets/img/lists/allies/Tournaments.png";

import stas from "./assets/img/lists/testimonials/pfp 6 1-min.png";
import adam from "./assets/img/lists/testimonials/pfp 1-min.png";
import omar from "./assets/img/lists/testimonials/pfp 2-min.png";
import ron from "./assets/img/lists/testimonials/pfp 3-min.png";
import yonatan from "./assets/img/lists/testimonials/pfp 4-min.png";
import nir from "./assets/img/lists/testimonials/pfp 5-min.png";

import facebook from "./assets/img/lists/backers/facebook.svg";
import nvidia from "./assets/img/lists/backers/nvidia.svg";
import amd from "./assets/img/lists/backers/AMD_Logo 1.svg";
import coinbase from "./assets/img/lists/backers/Coinbase 1.svg";

import microsoft from "./assets/img/lists/backers/Group 2181.svg";
import valve from "./assets/img/lists/backers/Valve_Software.svg";
import bsc from "./assets/img/lists/backers/Group.svg";
import opensea from "./assets/img/lists/backers/OpenSea-Full-Logo (light) 1.svg";

import p1 from "./assets/img/lists/powerPoints/p1.png";
import p2 from "./assets/img/lists/powerPoints/p2.png";
import p3 from "./assets/img/lists/powerPoints/p3.png";
import { mapURL } from "./config/keys";

export const mapUrl = mapURL || "";
/**
 * Showing feature cards
 *
 * @typedef {IFeatureCard[]}
 * @property {string} picUrl - The url of the feature card
 * @property {string} title - The title of the feature card
 * @property {string} text - The text of the feature card
 * @property {string} link - The link of the feature card
 */
export const features: IFeatureCard[] = [
  {
    picUrl: feature1,
    title: "Pick a Side",
    text: "Will you play as the highly organized Celestials, the chaotic Infernals or the shattered Ephemerals? Dfiance features unique factions, each with their lore, history and heroes.",
    link: "www.google.com",
  },
  {
    picUrl: feature2,
    title: "Play, Earn, Own",
    text: "Play the game, win battles and earn resources. Use your loot to upgrade your cards, unlock abilities, improve your gameplay or more. Alternatively, offer your resources on the marketplace to other players.",
    link: "www.google.com",
  },
  {
    picUrl: feature3,
    title: "Fair for All",
    text: "In order to ensure fair gameplay and to reduce entry barriers, Dfiance does not sell cards for cash or require players to make purchases. All content is fairly available for all players from the start.",
    link: "www.google.com",
  },
  {
    picUrl: feature4,
    title: "Limited Collectibles",
    text: "Dfiance game assets are collectibles that are associated with the player’s account. As a player, you own your assets and are free to use or trade or do as you please with them.",
    link: "www.google.com",
  },
];
/**
 * Introduction to the game.
 *
 * @typedef {Introduction[]}
 * @property {string} picUrl - The URL of the Introduction Image.
 * @property {string} title - The title of the Introduction.
 * @property {string} text - The text of the Introduction.
 * @property {img} effectPic - The URL of the Introduction Effect Image.
 * @property {vidUrl} effectVid - The URL of the Introduction Effect Video.
 */
export const introductions: Introduction[] = [
  {
    picUrl: intro1,
    title: "Claim your free deck",
    text: "Dfiance allows all players a free, fair start. No hidden bonuses, no special cards for special people, no limits. Everyone gets their initial randomized deck completely for free and can start playing immediately. Upgrading your deck can be done only through earned in-game resources.",
    effectPic: introEffectImg,
    effectVid: introEffectVid, //glowing smoke_VP9.webm
  },
  {
    picUrl: intro2,
    vidUrl: intro2Vid,
    title: "Lead your army and build your skills",
    text: "Choose which side you fight for from three different factions. Decide your battle strategy, collect and upgrade cards, abilities, war machines and even own your battleground and earn resources from every battle that occurs on it.",
  },
  {
    picUrl: intro3,
    vidUrl: intro3Vid,
    title: "Collect resources to fuel your success",
    text: "In-game resources are used to upgrade existing cards & abilities, unlock features, bet against other players, acquire new assets (cards, machines, abilities, battlegrounds), upgrade your war and harvesting machines or simply offer your hard-earned resources on the marketplace. ",
  },
];
/**
 * Represents the mechanics points of the game.
 *
 * @typedef {IMechanic[]}
 * @property {string} title - Title of the mechanic.
 * @property {string} text - Text of the mechanic.
 */
export const mechanisPoints: IMechanicPoint[] = [
  {
    title: "Deployable Cards",
    text: " At the start of the game, 10 random cards are drawn from each player's deck. Each player may redraw up to 2 of these cards. The final 10 cards will serve as the player's army in the coming battle.",
  },
  {
    title: "Deck",
    text: "Each player gets a deck of cards which he can upgrade and improve. \nCards that are not used in the match can be found in the deck.\nDecks can hold up to 39 cards.",
  },
  {
    title: "Graveyard",
    text: "If cards lose all their Attack Points (ATP), they are moved to the graveyard. \nThey can only return by using cards or spells with special abilities.\nAt the end of each round, all cards on the battlefield are sent to the graveyard.",
  },
  {
    title: "Turn Button & Score",
    text: "Keeps the current result of the battle (number of rounds won by each player)\nEach round, displays who’s turn it is, and the total accumulated Attack Points (ATP) of each player.",
  },
  {
    title: "Leader Avatar",
    text: "A customizable representation of yourself, on the battlefield. Each leader can use a special ability once a match.",
  },
  {
    title: "Artillery Row",
    text: "The Artillery Row can hold up to 10 Artillery cards. \nYou can recognize Artillery cards by the [Artillery] symbol on their top-right.",
  },
  {
    title: "Ranged Row",
    text: "The Ranged Row can hold up to 10 Ranged cards. \nYou can recognize Ranged cards by the [Ranged] symbol on their top-right.",
  },
  {
    title: "Brawler Row",
    text: "The Brawler Row can hold up to 8 Brawler cards. \nYou can recognize Brawler cards by the [Brawler] symbol on their top-right.",
  },

  {
    title: "Left Flank",
    text: "Each flank can be assigned a target enemy row when setting up your deck.\nEach flank row can hold up to 2 Brawler cards. Each card reduces the target row ATP by 1\nFlanks effects can be countered by deploying cards on the opposite flank (regardless of its target).",
  },
  {
    title: "Right Flank",
    text: "Each flank can be assigned a target enemy row when setting up your deck.\nEach flank row can hold up to 2 Brawler cards. Each card reduces the target row ATP by 1\nFlanks effects can be countered by deploying cards on the opposite flank (regardless of its target).",
  },
  {
    title: "War Machine",
    text: "War machines have significant impact on the course of the battle. \nYou can configure your war machines when setting up your deck.",
  },
  //Oppoenent
  {
    title: "Opponent Deployable Cards",
    text: " At the start of the game, 10 random cards are drawn from each player's deck. Each player may redraw up to 2 of these cards. The final 10 cards will serve as the player's army in the coming battle.",
  },
  {
    title: "Opponent Deck",
    text: "Each player gets a deck of cards which he can upgrade and improve. \nCards that are not used in the match can be found in the deck.\nDecks can hold up to 39 cards.",
  },
  {
    title: "Opponent Graveyard",
    text: "If cards lose all their Attack Points (ATP), they are moved to the graveyard. \nThey can only return by using cards or spells with special abilities.\nAt the end of each round, all cards on the battlefield are sent to the graveyard.",
  },
  {
    title: "Opponent Leader Avatar",
    text: "A customizable representation of yourself, on the battlefield. Each leader can use a special ability once a match.",
  },
  {
    title: "Opponent Artillery Row",
    text: "The Artillery Row can hold up to 10 Artillery cards. \nYou can recognize Artillery cards by the [Artillery] symbol on their top-right.",
  },
  {
    title: "Opponent Ranged Row",
    text: "The Ranged Row can hold up to 10 Ranged cards. \nYou can recognize Ranged cards by the [Ranged] symbol on their top-right.",
  },
  {
    title: "Opponent Brawler Row",
    text: "The Brawler Row can hold up to 8 Brawler cards. \nYou can recognize Brawler cards by the [Brawler] symbol on their top-right.",
  },

  {
    title: "Opponent Left Flank",
    text: "Each flank can be assigned a target enemy row when setting up your deck.\nEach flank row can hold up to 2 Brawler cards. Each card reduces the target row ATP by 1\nFlanks effects can be countered by deploying cards on the opposite flank (regardless of its target).",
  },

  {
    title: "Opponent Right Flank",
    text: "Each flank can be assigned a target enemy row when setting up your deck.\nEach flank row can hold up to 2 Brawler cards. Each card reduces the target row ATP by 1\nFlanks effects can be countered by deploying cards on the opposite flank (regardless of its target).",
  },
  {
    title: "Opponent War Machine",
    text: "War machines have significant impact on the course of the battle. \nYou can configure your war machines when setting up your deck.",
  },
];
/**
 * Array of objects representing mechanics of the game
 *
 * @typedef {IMechanic[]}
 * @property {string} picUrl - The url of the mechanic picture
 * @property {Array} points - array of object having properties representing text and title of the mechanic.
 */
export const mechanics: IMechanic[] = [
  {
    picUrl: mechanic1,
    points: [
      {
        text: "more than 300 unique characters",
        title: "300",
      },
      {
        text: "over 20 regions with an unrivaled history",
        title: "20",
      },
      {
        text: "over 100 extraordinary & unique battlegrounds",
        title: "100",
      },
    ],
  },
];
/**
 * Array of faction objects representing the factions in the game.
 *
 * @typedef {IFaction[]}
 * @property {string} picUrl - The URL for the faction's image.
 * @property {string} text - The name of the faction.
 * @property {Array} units - An array of units belonging to the faction.
 */
export const factions: IFaction[] = [
  {
    picUrl: infernal,
    text: "infernal",
    units: [],
  },

  {
    picUrl: Ephemeral,
    text: "Ephemeral",
    units: [],
  },

  {
    picUrl: Celestial,
    text: "celestial",
    units: [],
  },

  {
    picUrl: Factionless,
    text: "factionless",
    units: [],
  },
];
/**
 * Represents a section introducing Ephemeria World
 *
 * @typedef {Object} IWorld
 * @property {string} picUrl - The URL of the picture representing the world
 * @property {string} title - The title of the world
 * @property {string} text - The text introducing the ephemeria world
 */
export const world: IWorld = {
  picUrl: ethermeria,
  title: "Introducing Ephemeria",
  text: "Ephemeria is at the center of Dfiance. It is the land invaded by both Infernals and Celestial forces. Ephemeria is a rough world, with ice-cold caverns and burning hot deserts. Explore the world, find the different battlegrounds where battles are likely to happen, deploy energy harvesters and learn the history of the world.",
};
/**
 * An array of objects representing the different ally cards available in the game.
 *
 * @type {IAllyCard[]}
 * @property {string} picUrl - The URL of the image for the ally card.
 * @property {string} title - The title of the ally card.
 * @property {string} text - The description text for the ally card.
 */
export const allies: IAllyCard[] = [
  {
    picUrl: guild,
    title: "GuildS",
    text: "Create or join a guild, and work together with others in order to unlock powerful guild perks.",
  },
  {
    picUrl: tournaments,
    title: "Tournaments",
    text: "Tournaments are friendly games played against other guild members. The winner takes all.",
  },
  {
    picUrl: guildwars,
    title: "Guild Wars",
    text: "Challenge other guilds and battle each other for exclusive perks and unique guild rewards.",
  },
];
/**
 * Represents a testimonial from a user.
 *
 * @typedef {Object} ITestimonial
 * @property {string} picUrl - The URL of the user's profile picture.
 * @property {string} name - The name of the user.
 * @property {string} link - The URL of the user's website or social media profile.
 * @property {string} position - The user's position or title.
 * @property {string} text - The user's testimonial text.
 */
export const testimonials: ITestimonial[] = [
  {
    picUrl: stas,
    name: "STAS OSKIN",
    link: "www.google.com",
    position: "Founder at PONTEM NETWORK",
    text: "I’ve been in the industry since 2011 and i’ve never been as excited about a game as i am about Dfiance.",
  },
  {
    picUrl: omar,
    name: "Severus Lang",
    link: "www.google.com",
    position: "COO AT SAFEPRESS",
    text: "Dfiance is a well thought, beautifully designed game which includes a great number of features. It promises hours of fun all while maintaining all important gaming concepts.",
  },
  {
    picUrl: adam,
    name: "Adam Benayoun",
    link: "www.google.com",
    position: "Founder at Collider",
    text: "Dfiance sets a new, much higher design standard for the industry in general and games in particular. Absolutely beautiful!",
  },
  {
    picUrl: ron,
    name: "RON SADE",
    link: "www.google.com",
    position: "Founder at BRILLIANCE 3.0",
    text: "The Dfiance universe is huge and features mind-blowing art, beautiful game mechanics and exceptionally entertaining gameplay; all while the player maintains asset ownership.",
  },
  {
    picUrl: yonatan,
    name: "YONATAN BEN SHIMON",
    link: "www.google.com",
    position: "Founder at MATCHBOX DAO",
    text: "Dfiance is an outstanding blend of art, fun and technology created by a very ethical and professional team. I believe the game will perform very well.",
  },
  {
    picUrl: nir,
    name: "NIR BLUMBERGER",
    link: "www.google.com",
    position: "Founder at XP NETWORK",
    text: "The first time i saw Dfiance I was stunned by the quality of the illustrations, the soundtracks and the fact that its not just a game, but comes with an entire world to support it.",
  },
];
/**
 * Represents a backer who supports the project.
 *
 * @typedef {Object} IBacker
 * @property {string} picUrl - The URL of an image representing the backer.
 */
export const backers: IBacker[] = [
  {
    picUrl: facebook,
  },
  {
    picUrl: nvidia,
  },
  {
    picUrl: amd,
  },
  {
    picUrl: coinbase,
  },
  {
    picUrl: microsoft,
  },
  {
    picUrl: valve,
  },
  {
    picUrl: bsc,
  },
  {
    picUrl: opensea,
  },
];

export const deck: IDeckCard[] = [];
/**
 * Represents the Wiki Section
 *
 * @typedef {Object} WikiSection
 * @property {string} title - title of the section
 * @property {string} text - descriptive text of the section
 * @property {string} buttonText - text to be displayed on button
 * @property {url} link - link to be displayed on button
 */
export const wiki: IWiki = {
  title: "The Royal Library",
  text: "Everything there is to know about game, characters, and spells, battlegrounds, resources, war, & harvesting machines, avatars, and more.",
  buttonText: "OPEN WIKI",
  link: process.env.REACT_APP_WIKI_LINK,
};
/**
 * Represents a location on a map.
 *
 * @typedef {Object} MapLocation
 * @property {string} outline - The URL of an image representing the outline of the location.
 * @property {string} label - The URL of an image representing the label of the location.
 * @property {string} bgImage - The URL of an image to use as the background of the location.
 * @property {string} bgIcon - The URL of an image to use as the icon for the location on the map.
 */
export const askari: MapLocation = {
  outline:
    "https://drive.google.com/uc?export=download&id=1nMImW9m8t0efPmowbYo3eeU2rKcomP1F",
  label:
    "https://drive.google.com/uc?export=download&id=13vfqoVz_nP4rNIvDjI98vtf9QLhaqS5e",
  bgImage:
    "https://drive.google.com/uc?export=download&id=1dg_Hs-xKCRR776eAOF0N7HPw3Fso0IAG",
  bgIcon:
    "https://drive.google.com/uc?export=download&id=1cZMoWvBXGTx7X1DOsDnnWyfV4WqyUh_w",
};
/**
 * An array of objects representing power points for a presentation.
 *
 * @typedef {Object} PowerPoint
 * @property {string} pic - The URL of an image for the power point.
 * @property {string} text - The text to display for the power point.
 */
export const powerPoints: {
  pic: string;
  text: string;
}[] = [
  {
    text: "Unique Characters",
    pic: p2,
  },
  {
    text: "Regions With Unrivaled History",
    pic: p3,
  },
  {
    text: "Unique Battlegrounds",
    pic: p1,
  },
];
