import React, { useEffect, useMemo, useRef, useState } from "react";
import { UnitCarousel } from "../elements/carousels/unitCarousel";

import { factions } from "../../mockData";

import { useSelector } from "react-redux";

import { ReduxState } from "../../store/index";
import { ICard } from "../../store/types";

import { withServices, ServiceContainer } from "../../hocs/withServices";
/**
 * Represents a faction in the game.
 * @interface
 * @property {string} picUrl - The URL of the faction's picture.
 * @property {string} text - A description of the faction.
 * @property {ICard[]} units - The cards belonging to the faction.
 */
export interface IFaction {
  picUrl: string;
  text: string;
  units: ICard[];
}
/**
 * Component representing a faction card.
 * @param {Object} props - The props object.
 * @param {IFaction} props.card - The faction card object.
 * @param {number} props.selected - The index of the selected faction.
 * @param {Function} props.onSelect - The function to call when a faction is selected.
 * @param {number} props.index - The index of the faction.
 * @param {ServiceContainer} props.serviceContainer - The container for services used in the component.
 * @returns {JSX.Element} - The JSX element representing the faction card.
 */
const Faction = withServices(
  ({
    card,
    selected,
    onSelect,
    index,
    serviceContainer,
  }: {
    card: IFaction;
    selected: number;
    onSelect: (card: IFaction) => void;
    index: number;
    serviceContainer: ServiceContainer;
  }) => {
    const [intersected, setIntersected] = useState(false);
    const image = useRef(null);
    /**
     * A functional component for rendering a faction card.
     * @param {Object} props - The props object.
     * @param {IFaction} props.card - The faction card data.
     * @param {number} props.selected - The index of the selected card.
     * @param {Function} props.onSelect - The function to call when the card is selected.
     * @param {number} props.index - The index of the card.
     * @param {ServiceContainer} props.serviceContainer - The service container object.
     * @returns {JSX.Element} The JSX element for the Faction component.
     */
    useEffect(() => {
      serviceContainer.imageLoader.addRef(image, setIntersected);
    }, [serviceContainer.imageLoader]);

    return (
      <li
        className={`factionCard flexCol ${
          index === selected ? "selected" : ""
        }`}
        key={index}
        onClick={() => onSelect(card)}
      >
        <div className="circleWrapper">
          <img
            src={intersected ? card.picUrl : "#"}
            alt={`factionCard-${index}`}
            className="factionCard-picture"
            ref={image}
          />
        </div>
        <span className="upperText">{card.text}</span>
      </li>
    );
  }
);
/**
 * Factions component renders a list of factions with their respective cards and listens to user input to select a faction.
 * @param {object} props - Component props.
 * @param {object} props.serviceContainer - Service container object for dependency injection.
 * @returns {JSX.Element} - Rendered component.
 */
export const Factions = withServices((props) => {
  const { serviceContainer }: { serviceContainer: ServiceContainer } = props;

  const [selectedFaction, selectFaction] = React.useState<IFaction>(
    factions[0]
  );
  const cards = useSelector((state: ReduxState) => state.cards.items);

  const data: IFaction[] = factions.map((faction) => ({
    ...faction,
    units: cards.filter(
      (card) => card.Faction?.toLowerCase() === faction.text?.toLowerCase()
    ),
  }));

  const background = useRef(null);
  /**
   * useEffect hook that sets the initial selected faction to the first faction in the data array
   * and adds a reference to the background image to the image loader service provided by serviceContainer
   * @param {IFaction[]} data - An array of faction objects, each with a picUrl, text, and units property
   * @param {ServiceContainer} serviceContainer - An object containing references to different services used in the app
   */
  useEffect(() => {
    selectFaction(data[0]);

    serviceContainer.imageLoader.addRef(background, (load) => {
      if (load) {
        return {
          id: "factionSection",
          urls: {
            desk: require("../../assets/img/home/factionsGhostBg.png").default,
            mob: require("../../assets/img/home/mob/mobGreyStone.png").default,
          },
        };
      }
    });
  }, [cards]);
  /**
   * Returns the index of the selected faction in the data array
   * @returns {number} The index of the selected faction
   */
  const selectedIndex = useMemo(
    () => data.findIndex((item) => item.text === selectedFaction.text),
    [selectedFaction, data]
  );

  return (
    <div className="factionCards" ref={background}>
      <ul className="factionList noselect flexRow">
        {data.map((card, index) => (
          <Faction
            card={card}
            index={index}
            key={index}
            selected={selectedIndex}
            onSelect={(card: IFaction) => selectFaction(card)}
          />
        ))}
      </ul>

      {data.map((item, index) => (
        <div
          className="carouselContainer"
          key={`faction-${item.text}`}
          style={{ display: index === selectedIndex ? "block" : "none" }}
          id={`carouselContainer-${index}`}
        >
          <UnitCarousel faction={item} />
        </div>
      ))}
    </div>
  );
});
