import { createSlice } from "@reduxjs/toolkit";
import { Action } from "..";
import { IUser } from "../types";
/**
 * Interface for the Users slice of the Redux state
 */
export interface Users {
  users: IUser | null;
  accessToken: string;
  refreshToken: string;
}
/**
 * The initial state for the Users slice of the Redux state
 */

export const initialState: Users = {
  users: null,
  accessToken: "",
  refreshToken: "",
};
/**
 * Action interface for loading CreateUser
 */
interface CreateUser extends Action {
  payload: {
    users: IUser;
  };
}
/**
 * Action interface for loading setToken
 */
interface setToken extends Action {
  payload: {
    accessToken: string;
    refreshToken: string;
  };
}
/**
 * Slice for handling users-related data.
 */
export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    /**
     * Action creator for creating a new user.
     * @typedef {object} CreateUser
     * @property {object} payload - The user object to be created.
     * @property {string} payload.users.Email - The email of the user.
     * @property {string} payload.users.Wallet - The wallet address of the user.
     * @property {object} state - The current state of the user.
     */
    createUser: (state: Users, action: CreateUser) => {
      state.users = action.payload.users;
      localStorage?.setItem("Email", action.payload.users.Email);
      localStorage.setItem("Wallet", action.payload.users.Wallet);
    },
    /**
     * Action creator for setting user tokens.
     * @typedef {object} setToken
     * @property {object} payload - The tokens to be set.
     * @property {string} payload.accessToken - The access token.
     * @property {string} payload.refreshToken - The refresh token.
     * @property {object} state - The current state of the user.
     */
    setUserToken: (state: Users, action: setToken) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
    },
  },
});

export const { createUser, setUserToken } = users.actions;
