import React, { useState } from "react";
import burger from "../../assets/img/home/mob/burg.jpg";

/**
 * A component that renders a hamburger menu that toggles a mobile navigation menu.
 */
export const HamburgerMenu = () => {
  const [opened, setOpened] = useState(false);

  return (
    <div className={`burgerMenu${opened ? " toggled" : ""}`}>
      <img
        src={burger}
        alt="burger"
        onClick={() => setOpened(!opened)}
        width={32}
        height={32}
      />

      <nav className="headerMenu">
        <div className="hoverEffect">
          <a className="headerMenu-item goldHo" href="#featureSectionAnchor">
            Features
          </a>
        </div>
        <div className="hoverEffect">
          <a className="headerMenu-item" href="#mechanicSectionAnchor">
            Game Mechanics
          </a>
        </div>
        <div className="hoverEffect">
          <a className="headerMenu-item" href="#factionSectionAnchor">
            Faction Introduction
          </a>
        </div>
        <div className="hoverEffect">
          <a className="headerMenu-item" href="#wikiSectionAnchor">
            Learn
          </a>
        </div>
      </nav>
    </div>
  );
};
