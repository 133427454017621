import React, { useEffect } from "react";

import { Routes, Route } from "react-router";

import HomePage from "./Home";
import GameMechanicsPage from "./GameMechanics";

import { isWindows } from "../utils";
import Terms from "./TermsofUse";
import PrivacyPolicy from "./PrivacyPolicy";
import NotFound from "./404NotFound";
/**
 * Routes are defined here
 */
export const Router = () => {
  const isWin = isWindows();

  useEffect(() => {
    isWin && document.getElementById("portal")?.classList.add("win");
  }, []);
  /**
   * @return Returns all accessible routes of the website which are defined here.
   */
  return (
    <div className={`appContainer ${isWin ? "win" : ""}`}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/r/:id" element={<HomePage />} />
        <Route path="/gameMechanics" element={<GameMechanicsPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};
