// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState, useCallback, useMemo, useRef } from "react";

import { useSelector } from "react-redux";
import { ReduxState } from "../../store";

import { useWindowSize } from "../../hooks/useSize";

import Battlefield from "../../store/models/battlefield";
import { mechanisPoints } from "../../mockData";

const battlefield = Battlefield();
/**
 *@param {function} setSliderIndexOnClickBattleField - a function to set slider index on click of battlefield board
 */
const BattefieldBoard = ({ setSliderIndexOnClickBattleField }) => {
  const [mainBgLoaded, setLoaded] = useState("");
  const { width } = useWindowSize();
  const isMobile = useMemo(() => Number(width) <= 480, [width]);

  const refHover = useRef(null);
  const mechanicsCollection = battlefield.collectify(
    useSelector((state: ReduxState) => state.mechanics.items)
  );

  useEffect(() => {
    battlefield.load(isMobile).then(() => setLoaded("loaded"));
  }, [width]);
  /**
   *Handle hover effect when mouse enters any game element
   *@param {MouseEvent} e - Mouse event triggered on element
   *@param {string} location - location of game element
   */
  const onHoverOn = useCallback(battlefield.hoverHandler(), [width]);
  /**
   *Handle hover effect when mouse leaves any game element
   *@param {MouseEvent} e - Mouse event triggered on element
   *@param {string} location - location of game element
   */
  const onHoverOff = useCallback(battlefield.hoverOffHandler(), [width]);
  /**
   *Remove hover effect from game element
   *@param {string} location - location of game element
   */
  const onRemoveHover = useCallback(battlefield.removeHoverHandler(), [width]);

  const [isClicked, setClicked] = useState({
    key: "Deployable Cards",
    clicked: true,
  });
  /**
   *Get text to be displayed on hover effect
   *@param {string} location - location of game element
   *@returns {JSX.Element} - Text to be displayed
   */
  const getText = (location: string) => {
    return (
      <div className="ghostTextWrapper felxCol">
        <h5>{mechanicsCollection[location]?.Location}</h5>
        {/* <p>{mechanicsCollection[location]?.Text}</p> */}
      </div>
    );
  };
  /**
   *Render game element
   *@param {string} location - location of game element
   *@returns {JSX.Element} - Element to be rendered
   */
  const renderElement = (location: string) => {
    return <div className={`${location} boardBackground`}></div>;
  };
  /**
   *Handle click event on hover effect
   *e - Mouse event triggered on element
   *@param {string} location - location of game element
   */
  const handleGhostClick = (e: MouseEvent, location: string) => {
    const index = mechanisPoints.findIndex((e) => {
      return e.title === location;
    });
    if (index > -1) {
      setSliderIndexOnClickBattleField(index);
      onRemoveHover(isClicked.key);
      setClicked({ key: location, clicked: true });
    }
  };

  const renderGhost = (location: string) => {
    return (
      <div
        id="ghostEffect"
        className={`ghost ${location}`}
        onClick={(e) => handleGhostClick(e, location)}
        ref={refHover}
        // Pass the event object and location as parameters to onHoverOn
        onMouseEnter={(e) => {
          onHoverOn(e, location);
        }}
        // Conditionally call onHoverOff based on whether the ghost has been clicked or not
        onMouseLeave={
          !(isClicked.key === location && isClicked.clicked)
            ? isMobile
              ? 
                // If on mobile, do not call onHoverOff
                () => undefined
              : 
                // Pass the event object and location as parameters to onHoverOff
                (e) => onHoverOff(e, location)
            : undefined
        }
      >
        {getText(location)}
      </div>
    );
  };
  return (
    // Wrapper for the whole board
    <div className="boardWrapper">
      <div className="battlefieldBoard">
        {/* Background image for the board */}
        <div
          className={`battlefieldBackground boardBackground ${mainBgLoaded}`}
        ></div>
        {/* Elements of the game board */}
        {renderElement("Deployable Cards", 10)}
        {renderElement("Deck")}
        {renderElement("Graveyard")}
        {renderElement("Turn Button & Score")}
        {renderElement("Leader Avatar")}
        {renderElement("Artillery Row")}
        {renderElement("Ranged Row")}
        {renderElement("Brawler Row")}
        {renderElement("Left Flank")}
        {renderElement("Right Flank")}
        {renderElement("War Machine")}
        {renderElement("Opponent Deployable Cards")}
        {renderElement("Opponent Deck")}
        {renderElement("Opponent War Machine")}
        {renderElement("Opponent Graveyard")}
        {renderElement("Opponent Leader Avatar")}
        {renderElement("Opponent Artillery Row")}
        {renderElement("Opponent Ranged Row")}
        {renderElement("Opponent Brawler Row")}
        {renderElement("Opponent Left Flank")}
        {renderElement("Opponent Right Flank")}
        
        {/* Ghosts that appear on hover */}
        <div className="ghosts">
          {renderGhost("Deployable Cards")}
          {renderGhost("Deck")}
          {renderGhost("Graveyard")}
          {renderGhost("Turn Button & Score")}
          {renderGhost("Leader Avatar")}
          {renderGhost("Artillery Row")}
          {renderGhost("Ranged Row")}
          {renderGhost("Brawler Row")}
          {renderGhost("Left Flank")}
          {renderGhost("Right Flank")}
          {renderGhost("War Machine")}
          {renderGhost("Opponent Deployable Cards")}
          {renderGhost("Opponent Deck")}
          {renderGhost("Opponent War Machine")}
          {renderGhost("Opponent Graveyard")}
          {renderGhost("Opponent Leader Avatar")}
          {renderGhost("Opponent Artillery Row")}
          {renderGhost("Opponent Ranged Row")}
          {renderGhost("Opponent Brawler Row")}
          {renderGhost("Opponent Left Flank")}
          {renderGhost("Opponent Right Flank")}
        </div>
      </div>
    </div>
  );
};

export default BattefieldBoard;
