// Load environment variables from .env file
import { config as dotconf } from "dotenv";
dotconf();

// Define environment variables 
export const apiURL =
    process.env.REACT_APP_BASE_URL;
export const mapURL =
    process.env.REACT_APP_MAP_URL;
export const mapGoogleSheetUrls = process.env.REACT_APP_MAP_GOOGLE_URL
export const playButtonUrl =
    process.env.REACT_APP_PLAY_BUTTON_URL;
export const signUpButtonUrl = process.env.REACT_APP_SIGN_UP_BUTTON_URL;

export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_MAP_URL = process.env.REACT_APP_MAP_URL;
export const REACT_APP_MAP_GOOGLE_URL = process.env.REACT_APP_MAP_GOOGLE_URL;
export const REACT_APP_PLAY_BUTTON_URL = process.env.REACT_APP_PLAY_BUTTON_URL;
