import React, { useMemo } from "react";
import { withFeatureAliceCarousel } from "../../../hocs/withFeatureCarousel";
import { IFeatureCard } from "../feature";

import pad from "../../../assets/img/home/mob/ff.png";
/**
 * Renders a feature card for mobile devices
 * @param {Object} props - The component props
 * @param {IFeatureCard} props.feature - The feature card to display
 * @returns {JSX.Element} - The rendered component
 */
const Feature = ({ feature }: { feature: IFeatureCard }) => {
  return (
    <div className="mobileFeatureWrapper">
      <div className="mobileFeature">
        <img src={pad} alt="wPad" className="woodenPad" />
        <img
          src={feature.picUrl}
          alt={`mobileFeature-${feature.title}`}
          className="mobileFeature-pic"
        />
        <div className="cardText">
          <h3>{feature.title}</h3>
          <p>{feature.text}</p>
          <a href={feature.link}>Learn More</a>
        </div>
      </div>
    </div>
  );
};
/**
 * A carousel component for displaying feature cards.
 * @param {Object} props - The props object.
 * @param {IFeatureCard[]} props.features - An array of feature cards.
 * @returns {JSX.Element} - The FeatureCarousel component.
 */
export const FeatureCarousel = ({ features }: { features: IFeatureCard[] }) => {
  const featureCards = useMemo(
    () =>
      features.map((feature, index) => (
        <Feature feature={feature} key={`FeatureMob-${index}`} />
      )),
    [features]
  );

  return withFeatureAliceCarousel(featureCards, {
    paddingLeft: 40,
    paddingRight: 40,
    autoPlay: true,
  });
};
