import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { ReduxState } from "../../store";
import { SignUp } from "./signup";

/**
 * A button component for VOL token.
 * @param {boolean} main - A boolean indicating whether the button is the main button.
 * @param {string} classes - Additional CSS classes to apply to the button.
 * @param {string} id - The ID of the button element.
 * @returns {JSX.Element} The VolButton component.
 */
export const VolButton = ({
  classes = "",
  main,
  id,
}: {
  main?: boolean;
  classes?: string;
  id?: string;
}) => {
  const [, selectedUser] = useState({
    email: "",
    wallet: "",
  });
  const { Email, Wallet } = useSelector((state: ReduxState) => {
    return {
      Email: state.users.users?.Email,
      Wallet: state.users.users?.Wallet,
    };
  });
  const url = window.location.href;
  const parts = url.split("/");
  const refVar = parts[parts.length - 1];
  /**
   * useEffect hook that updates the selected user state with email and wallet information from local storage.
   * @param {string | null} localStorage.getItem("accessToken")} accessToken - The access token from local storage.
   * @return {void}
   */
  useEffect(() => {
    const _email = localStorage.getItem("Email") ?? "";
    const _wallet = localStorage.getItem("Wallet") ?? "";

    selectedUser({ email: Email || _email, wallet: Wallet || _wallet });
  }, [localStorage.getItem("accessToken")]);
  const portal = document.getElementById("portal");

  return (
    <>
      {portal &&
        main &&
        /**
         * Renders the sign-up component inside a portal container.
         * @param {string} id - The ID of the button that triggers the sign-up component.
         */
        createPortal(
          <div className={"portalContainer signUpContainer"}>
            <div
              className="signClose"
              onClick={() =>
                document
                  .querySelector("#portal .signUpContainer")
                  ?.classList.remove("visible")
              }
            ></div>
            <SignUp id={id} />
          </div>,
          portal
        )}
      <a
        // onClick={() =>
        // document
        //     .querySelector("#portal .signUpContainer")
        //     ?.classList.add("visible")
        // }
        href={`${process.env.REACT_APP_PLAY_BUTTON_URL}${
          refVar ? "?r=" + refVar : ""
        }`}
        target={"_blank"}
      >
        <button className={`${classes} volButton`}>Play For Free</button>
      </a>
    </>
  );
};
