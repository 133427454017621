import React, { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { ICard } from "../../store/types";
import { ReduxState } from "../../store";

import { CardImage } from "../elements/card/card";

import { useWindowSize } from "../../hooks/useSize";

import { withServices, ServiceContainer } from "../../hocs/withServices";

import { deckCardFabric } from "../../store/models/card";
import { DeckAnimator } from "../../services/deckAnimator";

import { CardInfo } from "../elements/card/cardInfo";

import { toggleCardInfo } from "../../store/index";

import { shortScreen } from "../../utils";
/**
 * Interface representing a IDeckCard refering to ICard.
 * @interface
 * @property {Array} coords - array of objects refering to coordinates of the card.
 * @property {number} angle - angle of the card.
 * @property {number} zCoord - z-coordinate of the card.
 * @property {number} yCoord - y-coordinate of the card.
 * @property {number} xCoord - x-coordinate of the card.
 */
export interface IDeckCard extends ICard {
  coord?: {
    angle: number;
    zCoord: number;
    yCoord: number;
    xCoord: number;
  };
}
/**
 * A component that represents a card in a deck.
 * @param {Object} props - The props that the component takes.
 * @param {IDeckCard} props.card - The card object to be rendered.
 * @param {number} props.rx - The x coordinate of the card.
 * @param {boolean} props.mobile - A boolean value that indicates whether the component is being rendered on mobile or not.
 * @param {DeckAnimator} props.deckAnimation - The deck animator object.
 * @returns {JSX.Element} - A JSX.Element representing the DeckCard component.
 */
const DeckCard = ({
  card,
  rx,
  mobile,
  deckAnimation,
}: {
  card: IDeckCard;
  rx: number;
  mobile: boolean;
  deckAnimation: DeckAnimator;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [visible, setIsVisible] = useState(false);
  /**
   * A React component representing a card in a deck
   * @param {IDeckCard} card - the card object
   * @param {number} rx - the current rotation of the deck
   * @param {boolean} mobile - whether the device is a mobile device
   * @param {DeckAnimator} deckAnimation - the deck animator object
   * @returns {JSX.Element} - a React element representing the deck card
   */
  useEffect(() => {
    const element = cardRef?.current as HTMLDivElement;

    if (element) {
      const deckCard = deckCardFabric(card, element);
      deckCard.setRx(rx);
      deckCard.positionSelf(rx, mobile);

      const mouseEnter = deckCard.enterHandler(deckAnimation);
      const mouseLave = deckCard.leaveHandler(deckAnimation);

      const rightClick = async (e: Event) => {
        document.getElementById("deckAnimation")?.classList?.add("stop");
        dispatch(toggleCardInfo(card.ID));
        const popup = deckCard.rightClickHandler(
          { yOffset: shortScreen ? 56 : 82 },
          deckAnimation
        );
        await popup(e);
        dispatch(toggleCardInfo(0));
        document.getElementById("deckAnimation")?.classList?.remove("stop");
      };
      //const mouseDown = deckCard.leftMouseDown(element);
      //const mouseUp = deckCard.leftMouseUp(element);

      element.addEventListener("mouseenter", mouseEnter);
      element.addEventListener("mouseleave", mouseLave);
      element.addEventListener("contextmenu", rightClick);
      mobile && element.addEventListener("click", rightClick);
      //!mobile && element.addEventListener("mousedown", mouseDown);
      //!mobile && element.addEventListener("mouseup", mouseUp);

      return () => {
        element.removeEventListener("mouseenter", mouseEnter);
        element.removeEventListener("mouseleave", mouseLave);
        element.removeEventListener("contextmenu", rightClick);
        //element.removeEventListener("mousedown", mouseDown);
        //element.removeEventListener("mouseup", mouseUp);
      };
    }
  }, [card, cardRef, mobile]);
  /**
   * Callback function for the Intersection Observer API that sets the visibility of an element
   * @param {IntersectionObserverEntry[]} entries - Array of IntersectionObserverEntry objects
   * that contain information about the intersection between the observed element and the viewport
   * @return {void}
   */
  const callBackWhenObserver = (entries: any) => {
    const [entry] = entries;

    setIsVisible(entry.isIntersecting);
  };
  /**
   * Runs an effect to observe the intersection of the target element with its root element and trigger a callback when a change occurs.
   * @param {Object} cardRef - A reference to the target HTML element.
   */
  useEffect(() => {
    const observer = new IntersectionObserver(callBackWhenObserver, {
      root: document.getElementById("deckCards"),
      rootMargin: "100px 100px 100px 100px",
      threshold: 0,
    });

    const currentTarget = cardRef.current as HTMLImageElement | null;

    if (currentTarget) {
      observer.observe(currentTarget);

      // loadedImage
    }
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [cardRef]);

  return (
    <div className="deckCard" ref={cardRef}>
      <CardImage
        unit={card}
        alt="deckCard"
        className="deckCard-picture"
        isVis={visible}
      />

      <CardInfo card={card} />
    </div>
  );
};
/**
 * Renders a deck of cards.
 * @param {Object} props - The component props.
 * @param {Object} props.serviceContainer - The service container used to provide dependencies.
 */
export const Deck = withServices((props) => {
  const [data, setData] = useState<IDeckCard[]>([]);
  const [rx, setRx] = useState(0);
  const [theta, setTheta] = useState<number[]>([]);
  const deckContainer = useRef<HTMLDivElement>(null);
  const deckWrapper = useRef<HTMLDivElement>(null);
  const cards = useSelector((state: ReduxState) => state.cards.items);
  const { width } = useWindowSize();
  const mobile = width ? width <= 480 : false;
  /**
   * Component for rendering a deck of cards.
   * @component
   * @param {Object} props - The component props.
   * @param {ServiceContainer} props.serviceContainer - The container for deck animation service.
   * @returns {JSX.Element} - The component JSX element.
   */
  const {
    serviceContainer: { deck: deckAnimation },
  }: { serviceContainer: ServiceContainer } = props;
  /**
   * Sets the state with an array of card objects that have a defined image property
   * @param {ICard[]} cards - The array of card objects to be filtered
   */
  useEffect(() => setData(cards.filter((card) => card.Img)), [cards]);
  /**
   * Sets up deck animation and event listeners for mouse and touch interactions.
   * @param {Object} props - The props object.
   * @param {ServiceContainer} props.serviceContainer - The service container.
   * @returns {JSX.Element} - The rendered deck component.
   */
  useEffect(() => {
    const container = document.getElementById("deckCards") as HTMLDivElement;

    if (data.length) {
      setRx((mobile ? 20 : 40) * data.length);
      setTheta(deckAnimation.caclTheta(data));
    }

    const clickOn = deckAnimation.leftMouseDown(deckContainer.current!);
    const clickOff = deckAnimation.leftMouseUp(deckContainer.current!);

    container.addEventListener("mousedown", clickOn);
    container.addEventListener("mouseleave", clickOff);
    container.addEventListener("touchstart", clickOn);
    container.addEventListener("touchend", clickOff);
    window.addEventListener("touchstart", clickOn);
    window.addEventListener("mouseup", clickOff);

    return () => {
      container.removeEventListener("touchstart", clickOn);
      container.removeEventListener("touchend", clickOff);
      container.removeEventListener("mousedown", clickOn);
      container.removeEventListener("mouseleave", clickOff);
      window.removeEventListener("mouseup", clickOff);
      window.removeEventListener("touchend", clickOff);
    };
  }, [data, mobile, deckAnimation]);

  return (
    <div className="deckCards" id="deckCards" ref={deckWrapper}>
      <div
        id="deckAnimation"
        className="animationContainer paused"
        style={deckAnimation.calcSize(rx)}
        ref={deckContainer}
      >
        {Array.isArray(data) &&
          data.map((card, index) => {
            const coord = {
              angle: deckAnimation.calcAngle(index, data.length),
              zCoord: deckAnimation.calcZ(index, data.length),
              yCoord: deckAnimation.calcY(rx, theta[index]),
              xCoord: deckAnimation.calcX(rx, theta[index]),
            };

            return (
              <DeckCard
                card={{
                  ...card,
                  coord,
                }}
                rx={rx}
                mobile={mobile}
                key={`deckCard-${card._id}`}
                deckAnimation={deckAnimation}
              />
            );
          })}
      </div>
    </div>
  );
});
