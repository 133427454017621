import React, { useState, useEffect, useRef } from "react";
import AliceCarousel from "react-alice-carousel";
/**
 * Represents the optional parameters that can be passed to a component or function.
 * @interface Options
 * @property {number} [paddingLeft] - The left padding of a component.
 * @property {number} [paddingRight] - The right padding of a component.
 * @property {"ltr" | "rtl"} [dir] - The direction of the component.
 * @property {boolean} autoPlay - A boolean value indicating whether a component should automatically start playing.
 * @property {number} [activeIndex] - The index of the active component.
 * @property {boolean} [infinite] - A boolean value indicating whether a component should infinitely loop.
 */
interface Options {
  paddingLeft?: number;
  paddingRight?: number;
  dir?: "ltr" | "rtl";
  autoPlay?: boolean;
  activeIndex?: number;
  infinite?: boolean;
}
/**
 * Calculates the starting index of a component based on the current window size and the given index.
 * @function startIndex
 * @param {number | undefined} index - The index to calculate the starting index from.
 * @returns {number | undefined} - The calculated starting index or `undefined` if `index` is not provided.
 */
function startIndex(index?: number): number | undefined {
  if (!index || undefined) return undefined;

  if (window.innerWidth < 800) {
    return index;
  } else if (window.innerWidth >= 800 && window.innerWidth < 1124) {
    return index <= 1 ? index : index - 1;
  } else if (window.innerWidth >= 1124 && window.innerWidth < 1650) {
    return index <= 1 ? index : index - 2;
  } else if (window.innerWidth >= 1650) {
    return index <= 1 ? index : index - 3;
  }
}
/**
 * withAliceCarousel is a Higher Order Component that wraps the AliceCarousel component.
 * It takes an array of items and optional options object.
 * It returns a functional component that renders the AliceCarousel component with the specified items and options.
 * @param items An array of items to render in the carousel.
 * @param options Optional object with configuration options for the carousel.
 * @returns A functional component that renders the AliceCarousel component with the specified items and options.
 */
export const withAliceCarousel = (items: any[], options: Options) => {
  const itemPadding = window.innerWidth < 480 ? 55 : 0;

  const [animate, setAnimate] = useState<boolean>(true);

  const carousel = useRef(null);

  const carousel1 = useRef<AliceCarousel | null>(null);

  const tabSwitchHandle = () => {
    if (document.visibilityState === "visible") {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  };
  /**
   * useEffect hook that observes the intersection of the carousel component and sets animation accordingly.
   * @param carouselRef A reference to the carousel component.
   * @param autoPlay A boolean value indicating whether or not the carousel should automatically play.
   */
  useEffect(() => {
    if (carousel.current) {
      const observer = new IntersectionObserver(
        async (entries: IntersectionObserverEntry[]) => {
          for (const entry of entries) {
            if (entry.isIntersecting == true) {
              setAnimate(entry.isIntersecting);
              entry.isIntersecting && carousel1.current?.slideNext(undefined);
            }
          }
        },
        { root: null, rootMargin: "500px 0px 500px 0px", threshold: 0 }
      );
      observer.observe(carousel.current);
    }
    // document.addEventListener("visibilitychange", tabSwitchHandle);
    return () =>
      document.removeEventListener("visibilitychange", tabSwitchHandle);
  }, [carousel.current]);
  return (
    <div className="unitCarousel" ref={carousel}>
      <div className="flexRow">
        <AliceCarousel
          infinite={true}
          ref={(node) => {
            if (node) {
              carousel1.current = node;
            }
          }}
          mouseTracking={true}
          disableButtonsControls
          autoPlay={animate ? options?.autoPlay : undefined}
          animationDuration={animate ? 10 * 350 : undefined}
          autoPlayInterval={animate ? 0 : undefined}
          animationEasingFunction="linear"
          //autoPlayDirection={dir}
          // autoPlayStrategy="none"
          disableDotsControls={true}
          touchTracking
          // touchMoveDefaultEvents={true}
          paddingLeft={options?.paddingLeft || itemPadding}
          paddingRight={options?.paddingRight || itemPadding}
          items={items}
          activeIndex={startIndex(options.activeIndex)}
          // activeIndex={
          //   options?.activeIndex
          //     ? window.innerWidth < 500
          //       ? options.activeIndex
          //       : options.activeIndex <= 1
          //       ? 1
          //       : options.activeIndex - 3
          //     : undefined
          // }
          responsive={{
            350: {
              items: 1,
            },
            480: {
              items: 2,
            },
            800: {
              items: 3,
            },
            1124: {
              items: 4,
            },
            1650: {
              items: 5,
            },
            2000: {
              items: 6,
            },
          }}
        />
      </div>
    </div>
  );
};
