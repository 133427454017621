import { createSlice } from "@reduxjs/toolkit";
import { Action } from "..";
import { IBattleground } from "../types";
/**
 * Interface for the battlegrounds slice of the Redux state
 */
export interface Battlegrounds {
  items: IBattleground[];
}
/**
 * The initial state for the battlegrounds slice of the Redux state
 */
export const initialState: Battlegrounds = {
  items: [],
};
/**
 * Action interface for loading battlegrounds
 */
interface ALoadBgs extends Action {
  payload: {
    battlegrounds: IBattleground[];
  };
}
/**
 * Redux slice for the battlegrounds
 */
export const battlegrounds = createSlice({
  name: "cards",
  initialState,
  reducers: {
    /**
     * Action to load the battlegrounds
     * @param state - The current state of the battlegrounds
     * @param action - The action object containing the battlegrounds to load
     */
    loadBattlegrounds: (state: Battlegrounds, action: ALoadBgs) => {
      state.items = action.payload.battlegrounds;
    },
  },
});

export const { loadBattlegrounds } = battlegrounds.actions;
