import * as React from "react";
/**
 * 
 * @returns Not found page will render if user try to access invalid route.
 */
const NotFound = () => {
  return (
    <div className="not-found">
      <h1>Oops!</h1>
      <h2>404</h2>
      <p>Not Found</p>
      <button className="volButton" onClick={()=>window.location.replace("/")}>
        Go Back to Home
      </button>
    </div>
  );
};

export default NotFound;
