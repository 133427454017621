import React, {
  ReactChild,
  ReactChildren,
  useRef,
  useEffect,
  useState,
} from "react";
import { ICard } from "../../../store/types";

import { deckCardFabric } from "../../../store/models/card";

import { withServices, ServiceContainer } from "../../../hocs/withServices";

import { CardType } from "../../../store/types";
/**
 * Renders a wrapper component for a card with lazy-loading image functionality and intersection observer for lazy-loading.
 * @param {Object} props - The component props.
 * @param {ICard} props.card - The card object to be rendered.
 * @param {ReactChild | ReactChildren} props.children - The child element(s) to be rendered within the wrapper.
 * @param {ServiceContainer} props.serviceContainer - The container for external services used within the component.
 * @param {Object} [props.style] - An optional object for inline styling.
 * @returns {JSX.Element} - The rendered CardWrapper component.
 */
export const CardWrapper = withServices(
  ({
    card,
    children,
    serviceContainer,
    style,
  }: {
    card: ICard;
    children: ReactChild | ReactChildren;
    serviceContainer: ServiceContainer;
    style?: {};
  }) => {
    const [url, setState] = useState("");

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const [intersectedFrame, setIntersectedFrame] = useState(false);

    const frame = useRef(null);
    /**
     * Effect hook that generates the card frame and sets the state with its source.
     * @param {ICard} card - The card data.
     * @param {React.MutableRefObject} frame.current - The reference to the card frame element.
     */
    useEffect(() => {
      if (frame.current) {
        const frameCard = deckCardFabric(card, frame.current);
        frameCard.getFrame().then((src) => setState(String(src)));
      }
    }, [card, frame.current]);
    /**
     * useEffect hook to add the reference of the card frame to the image loader
     * @param {React.RefObject<HTMLImageElement>} frame - The reference to the card frame image element
     * @param {function} setIntersectedFrame - The function to set the intersectedFrame state
     * @param {ServiceContainer} serviceContainer - The container for services
     */
    useEffect(() => {
      serviceContainer.imageLoader.addRef(frame, setIntersectedFrame);
    }, [serviceContainer.imageLoader]);

    return (
      <div className={`cardWrapper`} style={style}>
        <div
          className="cardFrame"
          ref={frame}
          style={{
            backgroundImage: `url(${intersectedFrame && url ? url : "#"})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className={`cardSymbol rowSymbol ${card.Row || ""}`}></div>
        <div
          className={`cardSymbol spellSymbol ${
            card.Type === CardType.Spell ? "Default" : card.Ability || ""
          }`}
        ></div>
        {card.Ability && (
          <div className={`cardSpellShutter ${card.Rarity || "Basic"}`}></div>
        )}

        <span
          className={`cardBasePower ${
            card["Base Power"] > 9 ? "longDigit" : ""
          }`}
        >
          {card["Base Power"]}
        </span>

        {/*!card["Base Power"] && card.Type === CardType.Conjured && (
          <span className={`cardBasePower spellball ${card.Faction}`}></span>
        )*/}

        {(card.Ability || card["Base Power"]) && (
          <span className="cardLevel">1</span>
        )}
        {true && (
          <div className={`hoverBlock ghostBg ${isSafari ? "safari" : ""}`}>
            <h5 className="trancentredX">{card.Name_EN}</h5>
            <div className="hoverBlock-text trancentredX">
              <span>{card.Ability}</span>
              <p>{card.abilityData?.Description}</p>
            </div>
          </div>
        )}
        {children}
      </div>
    );
  }
);
