import * as React from "react";
import frame from "../../assets/img/home/featureFrame.png";
/**
 * interface for ITestimonial
 * @interface
 * @property {string} picUrl - The url of the image for testimonal card.
 * @property {string} name - The name of the testimonal card.
 * @property {string} position - The position for testimonal card.
 * @property {string} text - The text in the testimonal card.
 * @property {string} link - The url of the testimonal card.
 */
export interface ITestimonial {
  picUrl: string;
  name: string;
  position: string;
  text: string;
  link: string;
}
/**
 * Functional component that renders a testimonal card.
 * @param {Object} props - Component props.
 * @param {ITestimonial} props.card - Object representing the testimonal card, including it's props.
 * @returns {JSX.Element} - Rendered component.
 */
export const TestimonialTemplate = ({ card }: { card?: ITestimonial }) => {
  card = card!;
  return (
    <div className="cardContainer">
      <img src={frame} alt="featureFrame" className="featureFrame" />
      <img src={frame} alt="featureFrame-1" className="featureFrame reversed" />

      <div className="testimonialTemplate flexCol">
        <img
          src={card.picUrl}
          alt={`testimonialCard-${card.name}`}
          className="testimonialCard-picture"
        />
        <div>
          <h3 className="upperText">{card.name}</h3>
          <span className="upperText">{card.position}</span>
        </div>
        <p>{card.text}</p>
      </div>
    </div>
  );
};
