import { createSlice } from "@reduxjs/toolkit";
import { Action } from "..";
import { ICard, IAbility } from "../types";
/**
 * Interface for the Cards slice of the Redux state
 */
export interface Cards {
  items: ICard[];
}
/**
 * The initial state for the Cards slice of the Redux state
 */
export const initialState: Cards = {
  items: [],
};
/**
 * Action interface for loading cards and abilities
 */
interface ALoadCards extends Action {
  payload: {
    cards: ICard[];
    abilities: IAbility[];
  };
}
/**
 * Cards slice interface representing the state of the cards feature.
 */
export const cards = createSlice({
  name: "cards",
  initialState,
  reducers: {
    /**
     * Reducer function that handles loading the cards into the state.
     * It also populates the "abilityData" property of cards that have an ability.
     */
    loadCards: (state: Cards, action: ALoadCards) => {
      const abilities = action.payload.abilities;
      state.items = action.payload.cards.map((card) =>
        card.Ability
          ? {
              ...card,
              abilityData: abilities.find(
                (ability) =>
                  ability.Name?.toLowerCase() === card.Ability?.toLowerCase()
              ),
            }
          : card
      );
    },
  },
});

export const { loadCards } = cards.actions;
