import { useRef, useEffect, useMemo } from "react";

import { withServices, ServiceContainer } from "../../hocs/withServices";

import { useWindowSize } from "../../hooks/useSize";
import { ReactComponent as ScrollUp } from "../../assets/img/home/cardNext.svg";

import { Socials } from "../../components/elements/socials";

import { scrollToTheTop } from "../../utils";

import footerLogo from "../../assets/img/home/Footer_logo.png";
/**
 * A functional component that renders a footer and takes in a props object.
 * @param {Object} props - The props object that contains a serviceContainer property.
 * @param {ServiceContainer} props.serviceContainer - An object that contains services needed for the component.
 * @returns {JSX.Element} - The JSX element that represents the rendered footer.
 */
const Footer = (props: any) => {
  const { serviceContainer }: { serviceContainer: ServiceContainer } = props;

  const { width } = useWindowSize();
  const mobile = useMemo(() => width && width <= 800, [width]);
  /**
   * A React hook that adds a reference to the image loader in the serviceContainer.
   * @param {Object} serviceContainer - An object that contains services needed for the component.
   * @param {Object} background - The background object that needs to be loaded.
   */
  useEffect(() => {
    serviceContainer.imageLoader.addRef(background, (load) => {
      if (load) {
        return {
          id: "footer",
          urls: {
            desk: require("../../assets/img/home/footerGhostBg.png").default,
            mob: [require("../../assets/img/home/mob/footerBgMob.png").default],
          },
        };
      }
    });
  }, [serviceContainer.imageLoader]);

  const background = useRef(null);

  return (
    <footer className="ghostBg" ref={background} id="footer">
      <div className="container">
        <div className="topFooter">
          <div className="scrollUp">
            <ScrollUp onClick={scrollToTheTop} />
            <span>Scroll Up</span>
          </div>
          <div className="flexRow footerContent">
            <img src={footerLogo} alt="footerLogo" className="footerLogo" />

            <div className="footerLinks">
              <div className="flexCol">
                <h3>Game</h3>
                <ul>
                  <li className="">
                    <a href="#featureSectionAnchor">Features</a>
                  </li>
                  <li className="">
                    <a href="#mechanicSectionAnchor">Game Mechanics</a>
                  </li>
                  <li className="">
                    <a href="#wikiSectionAnchor">Story</a>
                  </li>
                  <li className="" style={{ display: "none" }}>
                    <a href="">News</a>
                  </li>
                </ul>
              </div>
              <div className="flexCol">
                <h3>Art</h3>
                <ul>
                  <li className="">
                    <a
                      href={process.env.REACT_APP_CARDS_COLLECTION_LINK}
                      target="_blank"
                    >
                      Cards
                    </a>
                  </li>
                  <li className="">
                    <a
                      href={process.env.REACT_APP_BATTLEGROUND_LINK}
                      target="_blank"
                    >
                      Battlegrounds
                    </a>
                  </li>
                  <li className="" style={{ display: "none" }}>
                    <a href="">NFT</a>
                  </li>
                </ul>
              </div>
              <div className="flexCol">
                <h3>Help</h3>
                <ul>
                  <li className="">
                    <a
                      href={process.env.REACT_APP_DFIANCE_SUPPORT_EMAIL_LINK}
                      target="_blank"
                    >
                      Support
                    </a>
                  </li>
                  <li className="">
                    <a href={process.env.REACT_APP_FAQ_LINK} target="_blank">
                      FAQ
                    </a>
                  </li>
                  {false && (
                    <li className="">
                      <a href="">Story</a>
                    </li>
                  )}
                  <li className="">
                    <a href={process.env.REACT_APP_MEDIA_KIT} target="_blank">
                      Media Kit
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flexCol">
                <h3>
                    Socials
                </h3>
                <ul>
                  <li className="">
                    <a
                      href={process.env.REACT_APP_TWITTER_LINK}
                      target="_blank"
                    >
                      Twitter
                    </a>
                  </li>
                  <li className="">
                    <a
                      href={process.env.REACT_APP_DISCORD_LINK}
                      target="_blank"
                    >
                      Discord
                    </a>
                  </li>

                  <li className="">
                    <a
                      href={process.env.REACT_APP_TELEGRAM_LINK}
                      target="_blank"
                    >
                      Telegram
                    </a>
                  </li>

                  <li className="">
                    <a href={process.env.REACT_APP_BLOG_LINK} target="_blank">
                      Medium
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="bottomFooter">
          {mobile && <Socials />}
          <div className="container flexRow">
            <span className="copyRight">
              {`Ⓒ DFIANCE, ${new Date().getFullYear()}`}
            </span>

            <div className="misc flexRow">
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              <a href="/terms" target="_blank">
                Terms of Use
              </a>
            </div>

            {!mobile && <Socials />}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withServices(Footer);
