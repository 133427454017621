import { MouseEvent } from "react";
import { IMechanic } from "../types";
/**
 * Represents a collection of mechanics.
 * @typedef {Object} MechanicsCollection
 * @property {string} x - The key of the mechanic.
 * @property {IMechanic} IMechanic - The value of the mechanic.
 */
export type MechanicsCollection = {
  [x: string]: IMechanic;
};
/**
 * Represents a Battlefield.
 * @class
 */
class Battlefield {
  /**
   * Loads the battlefield.
   * @param {boolean} isMobile - Whether the user is on a mobile device or not.
   * @returns {Promise} A promise that resolves with a boolean value indicating whether the battlefield was loaded or not.
   */
  public load(isMobile: boolean) {
    return new Promise((resolve) => {
      const background = new Image();
      background.onload = function () {
        const { width } = background;

        const boardWrapper = document.querySelector(
          ".boardWrapper"
        ) as HTMLDivElement;

        const board = document.querySelector(
          ".battlefieldBoard"
        ) as HTMLDivElement;

        const ghosts = document.querySelector(
          "#root .battlefieldBoard .ghosts"
        ) as HTMLDivElement;

        if (isMobile) {
          const w = board.offsetHeight * 1.77;
          board.style.display = "block";
          board.style.width = `${w}px`;
          board.style.height = `${board.offsetHeight}px`;

          boardWrapper.scrollBy(w / 2.8, 0);
          //background.style.width = `${w}px`;
          ghosts.style.width = `100%`;
        } else {
          const h = board?.offsetHeight;

          const newWidth = (h * width) / 1080;

          ghosts.style.width = String(newWidth) + "px";
          board.style.width = `${newWidth}px`;
        }

        resolve(true);
      };
      background.onerror = function (e) {
        console.log(e);
        return resolve(true);
      };

      background.src =
        require("../../assets/img/battlefield/Battlefield_background-min.png").default;
    });
  }
  /**
   * Returns a function that handles mouse hover events.
   * @returns {function} A function that handles mouse hover events.
   */
  public hoverHandler() {
    return (_: MouseEvent<HTMLDivElement>, key: string) => {
      const ghost = this.getDomTarget(`.gameMechanicsPage .ghosts .ghost`, key);
      ghost.style.opacity = "1";
      const backgroud = this.getDomTarget(
        `.gameMechanicsPage .battlefieldBoard .boardBackground`,
        key
      );
      if (backgroud.style) {
        backgroud.style.opacity = "1";
      }
    };
  }
  /**
   * Returns a function that handles mouse hover off events.
   * @returns {function} A function that handles mouse hover off events.
   */
  public hoverOffHandler() {
    return (_: MouseEvent<HTMLDivElement>, key: string) => {
      const ghost = this.getDomTarget(`.gameMechanicsPage .ghosts .ghost`, key);
      ghost.style.opacity = "0";
      const backgroud = this.getDomTarget(
        `.gameMechanicsPage .battlefieldBoard .boardBackground`,
        key
      );
      backgroud!.style.opacity = "0";
    };
  }
  /**
   * Returns a function that removes the hover effect.
   * @returns {function} A function that removes the hover effect.
   */
  public removeHoverHandler() {
    return (key: string) => {
      const ghost = this.getDomTarget(`.gameMechanicsPage .ghosts .ghost`, key);
      ghost.style.opacity = "0";
      const backgroud = this.getDomTarget(
        `.gameMechanicsPage .battlefieldBoard .boardBackground`,
        key
      );
      if (backgroud.style) {
        backgroud.style.opacity = "0";
      }
    };
  }
  /**
   * Converts an array of mechanics to a MechanicsCollection object.
   * @param {IMechanic[]} mechanics - An array of mechanics.
   * @returns {MechanicsCollection} A MechanicsCollection object.
   */
  public collectify(mechanics: IMechanic[]) {
    return mechanics.reduce((obj, current) => {
      return {
        ...obj,
        [current.Location]: current,
      };
    }, {}) as MechanicsCollection;
  }
  /**
   * Returns the DOM element with the specified classes and key.
   * @param {string} classes - The classes of the DOM element.
   * @param {string} key - The key of the DOM element.
   * @returns {HTMLDivElement} The DOM element with the specified classes and key.
   */
  private getDomTarget(classes: string, key: string): HTMLDivElement {
    return document.body.querySelector(
      `${
        classes +
        key
          .split(" ")
          .map((word) => (word === "&" ? ".\\&" : `.${word}`))
          .join("")
      }`
    )!;
  }
}

export default () => new Battlefield();
export type { Battlefield };
