import React, { FC, useEffect } from "react";

import { withServices, ServiceContainer } from "../../hocs/withServices";

import { useDispatch } from "react-redux";

import { loadCards } from "../../store/reducer/cards";
import { loadMechanics } from "../../store/reducer/mechanics";
/**
 *Higher-order component that wraps the given App component and provides it with access to services via props.
 *@param {React.FC} App - The main application component.
 *@returns {React.FC} A new component that wraps the App component and provides it with access to services via props.
 */
export const AppContainer = (App: FC) =>
  withServices(function Callback(props) {
    const { serviceContainer }: { serviceContainer: ServiceContainer } = props;
    const dispatch = useDispatch();

    useEffect(() => {
      serviceContainer.api &&
        (async () => {
          const res = await serviceContainer.api.getCards();
          dispatch(
            loadCards({
              cards: res.cards,
              abilities: res.abilities,
            })
          );
          dispatch(
            loadMechanics({
              mechanics: res.mechanics,
            })
          );
        })();
    }, [serviceContainer.api]);

    return (
      <>
        <App />
      </>
    );
  });
