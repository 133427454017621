import * as React from "react";

import { ITextBlock, TextBlock } from "../elements/textBlock";

import frame from "../../assets/img/home/featureFrame.png";
/**
 * Interface for feature card
 * @interface
 * @property {string} picUrl - URL for feature image
 * @extends ITextBlock
 */
export interface IFeatureCard extends ITextBlock {
  picUrl: string;
}
/**
 * React component for feature card template
 * @component
 * @param {object} props - React props object
 * @param {IFeatureCard} props.card - Feature card data object
 * @param {number} props.index - Index of feature card
 * @returns {JSX.Element} - Rendered component
 */
export const FeatureTemplate = ({
  card,
  index,
}: {
  card?: IFeatureCard;
  index?: number;
}) => {
  return (
    <div className="cardContainer">
      <img src={frame} alt="featureFrame" className="featureFrame" />
      <img src={frame} alt="featureFrame-1" className="featureFrame reversed" />
      <div className="shineContainer">
        <div className="shine"></div>
      </div>
      <div className="featureTemplate">
        <img
          src={card?.picUrl}
          alt={`featureCard-${index}`}
          className="featureCard-picture"
        />
        <TextBlock card={card!} />
      </div>
    </div>
  );
};
