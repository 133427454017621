import axios from "axios";
import {
  ICard,
  IAbility,
  IMechanic,
  IBattleground,
  IUser,
} from "../store/types";

class Api {
  base = "";

  constructor(base: string) {
    this.base = base;
  }
  /**
   * Retrieves cards from the API.
   * @returns An object containing cards, abilities, and mechanics.
   */
  async getCards() {
    try {
      return (await (
        await fetch(this.base + "/cards", {
          headers: {
            Accept: "*",
            "Content-Type": "application/json",
          },
        })
      ).json()) as {
        cards: ICard[];
        abilities: IAbility[];
        mechanics: IMechanic[];
      };
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    }
  }
  /**
   * Retrieves Battlegrounds data from the API.
   * @returns An object containing battleground data.
   */
  getBattlegrounds = async () => {
    try {
      return (await (
        await fetch(this.base + "/battlegrounds", {
          headers: {
            Accept: "*",
            "Content-Type": "application/json",
          },
        })
      ).json()) as {
        battleground: IBattleground[];
      };
    } catch (e: any) {
      console.log(e);
      throw new Error(e.message);
    }
  };
  /**
   * Posts user data to the API.
   * @param user - The user data to be posted.
   * @returns A Promise containing the response from the API.
   */
  postUser = async (user: IUser) => {
    try {
      return await axios.post(this.base + "/users", user, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e: any) {
      console.log(e);
      throw new Error(e.message);
    }
  };
  /**
   * Validates a referral code.
   * @param code - The referral code to be validated.
   * @returns A Promise containing the response from the API.
   */
  validateReferralCode = async (code: string) => {
    try {
      return await axios.get(this.base + "/verifyReferralCode/" + code);
    } catch (e: any) {
      console.log(e);
      throw new Error(e.message);
    }
  };
}

export default (base: string) => new Api(base);
export type { Api };
